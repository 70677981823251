import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import CategoryNavbar from "../components/CategoryNavbar";
import Footer from "../components/Footer";
import GuestUserLoginModal from "../components/GuestUserLoginModal";
import Navbar from "../components/Navbar";
import { GLOBAL_URL } from "../config/global/constant";
import useAuth from "../config/hooks/useAuth";
import { ReactComponent as LoginImage } from "../static/img/login.svg";

const Login = () => {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");

	const { dispatch} = useAuth();

	const navigate = useNavigate();

	useEffect(() => {
		if (localStorage.getItem("token")) {
			navigate("/");
		}
	}, [])

	const handleSubmit = (e) => {
		e.preventDefault();
		if (!email) {
			toast.error("Email is required");
			return;
		}
		if (!password) {
			toast.error("Password is required");
			return;
		}
		axios
			.post(`${GLOBAL_URL}/auth/login`, {
				email,
				password,
			})
			.then((res) => {
				toast.success("Login successful");
				localStorage.setItem("token", res.data.data.token);
				localStorage.setItem("user", JSON.stringify(res.data.data.user));
				dispatch({
					type: "USER",
					payload: res.data.data.user,
				})
				navigate("/");
			})
			.catch((err) => {
				toast.error("Invalid credentials");
				console.log(err);
			});
	};

	return (
		<>
			<Navbar />
			<CategoryNavbar />
			<div className="container">
				<div className="row">
					<div className="col-md-6">
						<LoginImage className="p-5 img-fluid d-none d-md-block" />
					</div>
					<div className="col-md-6 d-flex justify-content-start align-items-center flex-column mt-5">
						<h2 className="text-start py-5 w-75 fst-italic fw-bold">
							Login
						</h2>
						<form className="d-flex flex-column justify-content-center align-items-center w-100">
							<div className="mb-3 form-floating w-75">
								<input
									type="text"
									placeholder="Email"
									className="form-control"
									id="email"
									value={email}
									onChange={(e) => setEmail(e.target.value)}
								/>
								<label htmlFor="email">Email</label>
							</div>
							<div className="mb-1 form-floating w-75">
								<input
									type="password"
									className="form-control"
									placeholder="Password"
									id="password"
									value={password}
									onChange={(e) =>
										setPassword(e.target.value)
									}
								/>
								<label htmlFor="password">Password</label>
							</div>
							<div
								className="w-75 text-smaller"
							>
								<p className="text-end fw-semibold">
									&nbsp;
								</p>
							</div>
							<div className="d-flex w-75">
								<p className="text-smaller text-start">
									By continuing you agree to the{" "}
									<Link
										className="text-orange fw-semibold"
										to={"/terms-and-conditions"}
									>
										Terms of User
									</Link>{" "}
									and{" "}
									<Link
										className="text-brown fw-semibold"
										to={"/privacy-policy"}
									>
										Privacy Policy
									</Link>
								</p>
							</div>
							<button
								type="submit"
								onClick={(e) => handleSubmit(e)}
								className="btn btn-tertiary w-75"
							>
								Login
							</button>
							{/* create a button for login via otp */}
							<div className="d-flex justify-content-center align-items-center w-75">
								<p className="text-smaller text-center">
									Or
								</p>
							</div>
							<button
								type="button"
								className="btn btn-creme w-75 fs-6"
								data-bs-toggle="modal"
								data-bs-target={"#guestModal"}
							>
								Login via OTP
							</button>
						</form>
						<div className="border-bottom mt-4 w-75"></div>
						<p className="text-center mt-4">
							Don't have an account?{" "}
							<Link
								className="text-orange fw-semibold"
								to={"/register"}
							>
								Register
							</Link>
						</p>
					</div>
				</div>
			</div>
			<GuestUserLoginModal login={true} />
			<Footer />
		</>
	);
};

export default Login;
