import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { GLOBAL_URL } from "../config/global/constant";
import useAuth from "../config/hooks/useAuth";

const AddressSettings = () => {
	const { state, dispatch } = useAuth();
	const [addressList, setAddressList] = useState([]);
	const [newAddress, setNewAddress] = useState({
		type: "Home",
		name: "",
		phone: "",
		addressLine1: "",
		addressLine2: "",
		pincode: "",
		city: "",
		state: "",
		country: "India",
	});
	const [selectedAddress, setSelectedAddress] = useState(null);
	const [editAddress, setEditAddress] = useState(false);

	const handleAddAddress = async () => {
		const url = editAddress
			? `${GLOBAL_URL}/auth/address/${selectedAddress.id}`
			: `${GLOBAL_URL}/auth/address`;
		if (editAddress) {
			await axios
				.put(
					url,
					{
						address_type: newAddress.type,
						name: newAddress.name,
						phone_number: newAddress.phone,
						address_line_1: newAddress.addressLine1,
						address_line_2: newAddress.addressLine2,
						pin_code: newAddress.pincode,
						city: newAddress.city,
						state: newAddress.state,
						country: newAddress.country,
					},
					{
						headers: {
							"Content-Type": "application/json",
							Authorization: `Token ${localStorage.getItem(
								"token"
							)}`,
						},
					}
				)
				.then((res) => {
					const user = JSON.parse(localStorage.getItem("user"));
					// check if address is already present
					const index = user.address.findIndex(
						(address) => address.id === res.data.data.id
					);
					console.log("index", index);
					if (index === -1) {
						user.address.push(res.data.data);
					} else {
						user.address[index] = res.data.data;
					}
					localStorage.setItem("user", JSON.stringify(user));
					dispatch({ type: "USER", payload: user });
					setNewAddress({
						type: "Home",
						name: "",
						phone: "",
						addressLine1: "",
						addressLine2: "",
						pincode: "",
						city: "",
						state: "",
						country: "India",
					});
					toast.success("Address Updated");
				})
				.catch((err) => {
					console.log(err);
					toast.error("Invalid Address");
				});
		} else {
			await axios
				.post(
					url,
					{
						address_type: newAddress.type,
						name: newAddress.name,
						phone_number: newAddress.phone,
						address_line_1: newAddress.addressLine1,
						address_line_2: newAddress.addressLine2,
						pin_code: newAddress.pincode,
						city: newAddress.city,
						state: newAddress.state,
						country: newAddress.country,
					},
					{
						headers: {
							"Content-Type": "application/json",
							Authorization: `Token ${localStorage.getItem(
								"token"
							)}`,
						},
					}
				)
				.then((res) => {
					const user = JSON.parse(localStorage.getItem("user"));
					user.address.push(res.data.data);
					localStorage.setItem("user", JSON.stringify(user));
					dispatch({ type: "USER", payload: user });
					setNewAddress({
						type: "Home",
						name: "",
						phone: "",
						addressLine1: "",
						addressLine2: "",
						pincode: "",
						city: "",
						state: "",
						country: "India",
					});
					toast.success("Address Added");
				})
				.catch((err) => {
					console.log(err);
					toast.error("Invalid Address");
				});
		}
	};

	const handleDeleteAddress = async (id) => {};

	useEffect(() => {
		setAddressList(JSON.parse(localStorage.getItem("user")).address);
		if (!selectedAddress) {
			setSelectedAddress(
				JSON.parse(localStorage.getItem("user")).address[0]
			);
		}
    // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state]);

	return (
		<div className="row p-4">
			<div className="col-12">
				<p className="mb-0 fs-6 fw-bold">Manage Addresses</p>
				<hr />
				<div
					className="border p-2 d-flex align-items-center gap-2 cursor-pointer bg-primary"
					data-bs-toggle="modal"
					data-bs-target="#addAddressModal"
					onClick={() => {
						setNewAddress({
							type: "Home",
							name: "",
							phone: "",
							addressLine1: "",
							addressLine2: "",
							pincode: "",
							city: "",
							state: "",
							country: "India",
						})
                        setEditAddress(false);
                    }}
				>
					<i className="fas fa-plus text-brown"></i>
					<p className="mb-0 fs-6 fw-bold text-brown">
						Add New Address
					</p>
				</div>
				<div className="row">
					{addressList.length > 0 ? (
						addressList.map((item, index) => (
							<div className="col-12" key={index}>
								<div className={`border p-2`}>
									<div className="mb-0 fs-7 d-flex justify-content-between align-items center">
										<div className="fw-bold d-flex gap-2 align-items-end">
											<div className="fs-6">
												{item.name}
											</div>
											<div className="fw-normal fs-8 mb-0-5">
												{item.phone_number}
											</div>
											<div className="text-white fs-9 bg-tertiary py-1 px-2 rounded d-flex justify-content-center align-items-center">
												{item.address_type}
											</div>
										</div>
										<div className="dropdown">
											<i
												className="fas fa-ellipsis-h text-brown cursor-pointer fs-6"
												id="dropdownMenuButton1"
												data-bs-toggle="dropdown"
												aria-expanded="false"
											></i>
											<ul
												className="dropdown-menu"
												aria-labelledby="dropdownMenuButton1"
											>
												<li>
													<p
														className="dropdown-item mb-0 cursor-pointer"
														data-bs-toggle="modal"
														data-bs-target={
															"#addAddressModal"
														}
														onClick={() => {
															setSelectedAddress(
																item
															);
															setNewAddress({
																type: item.address_type,
																name: item.name,
																phone: item.phone_number,
																addressLine1:
																	item.address_line_1,
																addressLine2:
																	item.address_line_2,
																pincode:
																	item.pin_code,
																city: item.city,
																state: item.state,
																country:
																	item.country,
															});
															setEditAddress(
																true
															);
														}}
													>
														Edit
													</p>
												</li>
												<li>
													<p
														className="dropdown-item mb-0 cursor-pointer"
														data-bs-toggle="modal"
														data-bs-target="#deleteAddressModal"
													>
														Delete
													</p>
												</li>
											</ul>
										</div>
									</div>
									<div className="mt-2 fs-7">
										{item.address_line_1},{" "}
										{item.address_line_2}, {item.city},{" "}
										{item.state}, {item.country},{" "}
										{item.pin_code}
									</div>
								</div>
							</div>
						))
					) : (
						<div className="col-12">No Address Found</div>
					)}
				</div>
			</div>
			<div
				className="modal fade"
				id="addAddressModal"
				tabIndex="-1"
				aria-labelledby="exampleModalLabel"
				aria-hidden="true"
			>
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<h1
								className="modal-title fs-5"
								id="exampleModalLabel"
							>
								Add New Address
							</h1>
							<button
								type="button"
								className="btn-close"
								data-bs-dismiss="modal"
								aria-label="Close"
							></button>
						</div>
						<div className="modal-body">
							<div class="container">
								<div class="row mx-0 justify-content-center">
									<div class="col-12 d-flex flex-column gap-3">
										<div className="row d-flex align-items-center">
											<div className="col-4">
												<div
													className={`btn w-100 fs-7 sizeBtn ${
														newAddress.type ===
														"Home"
															? "sizeBtn-selected"
															: ""
													}`}
													onClick={() =>
														setNewAddress({
															...newAddress,
															type: "Home",
														})
													}
												>
													Home
												</div>
											</div>
											<div className="col-4">
												<div
													className={`btn w-100 fs-7 sizeBtn ${
														newAddress.type ===
														"Office"
															? "sizeBtn-selected"
															: ""
													}`}
													onClick={() =>
														setNewAddress({
															...newAddress,
															type: "Office",
														})
													}
												>
													Office
												</div>
											</div>
											<div className="col-4">
												<div
													className={`btn w-100 fs-7 sizeBtn ${
														newAddress.type ===
														"Other"
															? "sizeBtn-selected"
															: ""
													}`}
													onClick={() =>
														setNewAddress({
															...newAddress,
															type: "Other",
														})
													}
												>
													Other
												</div>
											</div>
										</div>
										<input
											name="name"
											type="text"
											class="form-control"
											placeholder="Name *"
											value={newAddress.name}
											onChange={(e) =>
												setNewAddress({
													...newAddress,
													name: e.target.value,
												})
											}
										/>
										<input
											name="name"
											type="text"
											class="form-control"
											placeholder="Phone Number *"
											maxLength={10}
											minLength={10}
											value={newAddress.phone}
											onChange={(e) =>
												setNewAddress({
													...newAddress,
													phone: e.target.value,
												})
											}
										/>
										<input
											name="address1"
											type="text"
											class="form-control"
											placeholder="Address Line 1 *"
											value={newAddress.addressLine1}
											onChange={(e) =>
												setNewAddress({
													...newAddress,
													addressLine1:
														e.target.value,
												})
											}
										/>
										<input
											name="address1"
											type="text"
											class="form-control"
											placeholder="Address Line 2"
											value={newAddress.addressLine2}
											onChange={(e) =>
												setNewAddress({
													...newAddress,
													addressLine2:
														e.target.value,
												})
											}
										/>
										<input
											name="address1"
											type="text"
											class="form-control"
											placeholder="Pincode *"
											value={newAddress.pincode}
											onChange={(e) =>
												setNewAddress({
													...newAddress,
													pincode: e.target.value,
												})
											}
										/>
										<input
											name="address1"
											type="text"
											class="form-control"
											placeholder="City *"
											value={newAddress.city}
											onChange={(e) =>
												setNewAddress({
													...newAddress,
													city: e.target.value,
												})
											}
										/>
										<input
											name="address1"
											type="text"
											class="form-control"
											placeholder="State *"
											value={newAddress.state}
											onChange={(e) =>
												setNewAddress({
													...newAddress,
													state: e.target.value,
												})
											}
										/>
										<input
											name="address1"
											type="text"
											class="form-control"
											placeholder="Country *"
											value={newAddress.country}
											onChange={(e) =>
												setNewAddress({
													...newAddress,
													country: e.target.value,
												})
											}
										/>
									</div>
								</div>
							</div>
						</div>
						<div className="modal-footer">
							<button
								type="button"
								className="btn btn-tertiary"
								data-bs-dismiss="modal"
								onClick={() => handleAddAddress()}
							>
								Save
							</button>
						</div>
					</div>
				</div>
			</div>
			{/* Modal to delete the address */}
			<div
				className="modal fade"
				id="deleteAddressModal"
				tabindex="-1"
				aria-labelledby="exampleModalLabel"
				aria-hidden="true"
			>
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title" id="exampleModalLabel">
								Delete Address
							</h5>
							<button
								type="button"
								className="btn-close"
								data-bs-dismiss="modal"
								aria-label="Close"
							></button>
						</div>
						<div className="modal-body">
                            <p className="mb-0">
							    Are you sure you want to delete this address?
                            </p>
                            <div className="d-flex justify-content-end align-items-center gap-2 mt-4">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-bs-dismiss="modal"
                                >
                                    Close
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-danger"
                                    onClick={() => handleDeleteAddress()}
                                >
                                    Delete
                                </button>
                            </div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AddressSettings;
