import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";
import Carousel from "../components/Carousel";
import CategoryNavbar from "../components/CategoryNavbar";
import Footer from "../components/Footer";
import HomeSubCategoryProductList from "../components/HomeSubCategoryProductList";
import Navbar from "../components/Navbar";
import { GLOBAL_URL } from "../config/global/constant";
import Testimonials from "../components/testimonialSection";
import UspSection from "../components/UspSection";
import testimonials from "../assets/testimonial";
import ShopByCategory from "../components/shopByCategory";
import Parallax from "../components/Parallax";
// import ProductList from "../components/ProductList";
import useAuth from "../config/hooks/useAuth";

const Home = () => {
	const { productData } = useAuth();
	const { categoryData } = useAuth();
	const [newLaunchProducts, setNewLaunchProducts] = useState([]);
	const [modalData, setModalData] = useState({
		name: "",
		description: "",
		images: [],
	});
	console.log('find this commento', categoryData)
	const getNewLaunchProducts = async () => {
		await axios
			.get(`${GLOBAL_URL}/product/new-launch`)
			.then((res) => {
				setNewLaunchProducts(res.data.data);
			})
			.catch((err) => {
				toast.error("Something went wrong!");
				console.log(err);
			});
	};

	useEffect(() => {
		getNewLaunchProducts();
	}, []);

	return (
		<>
			<Navbar />
			<CategoryNavbar />
			<Carousel />
			<ShopByCategory categories = {categoryData} />
			{
				newLaunchProducts.length > 0 && (
					<HomeSubCategoryProductList
						products={newLaunchProducts}
						catName={"New Launches"}
						viewAll={false}
						newLaunch={true}
						setModalData={setModalData}
					/>
				)
			}
			{productData.map(
				(item, index) =>
					item.products.length > 0 && (
						<HomeSubCategoryProductList
							key={index}
							products={item.products}
							catName={item.name}
							id={item.id}
						/>
					)
			)}
			<Parallax image='banner.webp' />
			<Testimonials testimonials={testimonials} />
			<UspSection />
			<div
				className="modal fade"
				id="comingSoonModal"
				tabindex="-1"
				aria-labelledby="comingSoonModalLabel"
				aria-hidden="true"
			>
				<div className="modal-dialog modal-lg">
					<div className="modal-content">
						<div className="modal-header">
							<h1
								className="modal-title fs-5"
								id="comingSoonModalLabel"
							>
								Coming Soon ...
							</h1>
							<button
								type="button"
								className="btn-close"
								data-bs-dismiss="modal"
								aria-label="Close"
							></button>
						</div>
						<div className="modal-body">
							<div className="container">
								<div className="row">
									<div
										id="comingSoonItemModal"
										className="carousel slide"
										data-bs-ride="true"
									>
										<div className="carousel-indicators">
											{modalData.images.map(
												(item, index) => (
													<button
														key={index}
														type="button"
														data-bs-target="#comingSoonItemModal"
														data-bs-slide-to={index}
														className={
															index === 0
																? "active"
																: ""
														}
														aria-current="true"
														aria-label={`Slide ${
															index + 1
														}`}
													></button>
												)
											)}
										</div>
										<div className="carousel-inner">
											{modalData.images.map(
												(item, index) => (
													<div
														className={`carousel-item ${
															index === 0
																? "active"
																: ""
														}`}
														key={index}
													>
														<img
															src={`${item}`}
															className="d-block w-100"
															alt="..."
														/>
														<img
															src={`${item}`}
															className="d-block w-100"
															alt="..."
														/>
														<div className="overlay-img"></div>
													</div>
												)
											)}
										</div>
										<button
											className="carousel-control-prev"
											type="button"
											data-bs-target="#comingSoonItemModal"
											data-bs-slide="prev"
										>
											<span
												className="carousel-control-prev-icon text-dark"
												aria-hidden="true"
											></span>
											<span className="visually-hidden">
												Previous
											</span>
										</button>
										<button
											className="carousel-control-next"
											type="button"
											data-bs-target="#comingSoonItemModal"
											data-bs-slide="next"
										>
											<span
												className="carousel-control-next-icon"
												aria-hidden="true"
											></span>
											<span className="visually-hidden">
												Next
											</span>
										</button>
									</div>
								</div>
								<div className="row">
									<div className="col-12">
										<h1 className="fs-5 fw-bold">
											{modalData.name}
										</h1>
										<p>{modalData.description}</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</>
	);
};

export default Home;
