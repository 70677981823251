import '../assets/testimonialStyle.css'
function Testimonial({name, description, rating, image}) {
    
    return (
        <div className="testimonial-wrapper">
            {image && 
                <img src={image} className="testimonial-image rounded-circle" alt={`${name}'s Review`} />
            }
            {rating && 
                <div className="ratings">
                    {
                        Array.from({ length: rating }).map((_, index) => (
                            <span key={index} className="star-icon">⭐</span>
                          ))
                    }
                </div>
            }
            {description &&
                <div className="review-description">
                    {description}
                </div>
            }
            {name && 
                <p className="reviewr-name">{name}</p>
            }
        </div>
    )
}

const testimonialSection = ({testimonials}) => {
    return  (
        <section className="testimonials d-flex flex-column m-2 my-4 p-4">
            <div className="testimonials-heading section-heading h2 text-center uppercase">Testimonials</div>
            <div className="testimonials-inners">
                {testimonials.map(item => Testimonial(item))}
            </div>
        </section>
    )
} 

export default testimonialSection