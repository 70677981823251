import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import CategoryNavbar from "../components/CategoryNavbar";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import { GLOBAL_URL } from "../config/global/constant";
import { ReactComponent as RegisterImage } from "../static/img/register.svg";

const Register = () => {
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [phoneNumber, setPhoneNumber] = useState("");

    const navigation = useNavigate();

	const handleSubmit = (e) => {
		e.preventDefault();
        if (!name) {
            toast.error("Name is required");
            return;
        }
        if (!email) {
            toast.error("Email is required");
            return;
        }
        if (!password) {
            toast.error("Password is required");
            return;
        }
        if (!confirmPassword) {
            toast.error("Confirm password is required");
            return;
        }
        if (!phoneNumber) {
            toast.error("Phone number is required");
            return;
        }
        if (phoneNumber.length !== 10) {
            toast.error("Phone number must be of 10 characters only");
            return;
        }
        if (password.length < 6) {
            toast.error("Password must be at least 6 characters long");
            return;
        }
        if (password !== confirmPassword) {
            toast.error("Passwords do not match");
            return;
        }
		axios
			.post(`${GLOBAL_URL}/auth/register`, {
				name,
				email,
				password,
				phone_number: phoneNumber,
			})
			.then((res) => {
                toast.success("Registration successful");
                navigation("/login")
			})
			.catch((err) => {
                toast.error("Registration failed");
				console.log(err);
			});
	};

    useEffect(() => {
		if (localStorage.getItem("token")) {
			navigation("/");
		}
	}, [])

	return (
		<>
			<Navbar />
			<CategoryNavbar />
			<div className="container">
				<div className="row">
					<div className="col-md-6 d-flex justify-content-start align-items-center flex-column mt-5">
						<h2 className="text-start py-2 w-75 fst-italic fw-bold">
							Register
						</h2>
						<form className="d-flex flex-column justify-content-center align-items-center w-100">
							<div className="mb-1 form-floating w-75">
								<input
									type="text"
									placeholder="Name"
									className="form-control"
									id="name"
									value={name}
									onChange={(e) => setName(e.target.value)}
                                    required
								/>
								<label htmlFor="name">Name</label>
							</div>
							<div className="mb-1 form-floating w-75">
								<input
									type="email"
									placeholder="Email"
									className="form-control"
									id="email"
									value={email}
									onChange={(e) => setEmail(e.target.value)}
                                    required
								/>
								<label htmlFor="email">Email</label>
							</div>
							<div className="mb-1 form-floating w-75">
								<input
									type="tel"
									placeholder="Phone Number"
									className="form-control"
									id="phoneNumber"
									value={phoneNumber}
                                    maxLength={10}
									onChange={(e) =>
										setPhoneNumber(e.target.value)
									}
                                    required
								/>
								<label htmlFor="phoneNumber">
									Phone Number
								</label>
							</div>
							<div className="mb-1 form-floating w-75">
								<input
									type="password"
									className="form-control"
									placeholder="Password"
									id="password"
									value={password}
									onChange={(e) =>
										setPassword(e.target.value)
									}
                                    required
								/>
								<label htmlFor="password">Password</label>
							</div>
							<div className="mb-1 form-floating w-75">
								<input
									type="password"
									className="form-control"
									placeholder="Confirm Password"
									id="confirmPassword"
									value={confirmPassword}
									onChange={(e) =>
										setConfirmPassword(e.target.value)
									}
                                    required
								/>
								<label htmlFor="password">
									Confirm Password
								</label>
							</div>
							<div className="d-flex w-75">
								<p className="text-smaller text-start">
									By continuing you agree to the{" "}
									<Link
										className="text-orange fw-semibold"
										to={"/terms-and-conditions"}
									>
										Terms of User
									</Link>{" "}
									and{" "}
									<Link
										className="text-brown fw-semibold"
										to={"/privacy-policy"}
									>
										Privacy Policy
									</Link>
								</p>
							</div>
							<button type="submit" onClick={(e) => handleSubmit(e)} className="btn btn-tertiary w-75">
								Register
							</button>
						</form>
						<div className="border-bottom mt-4 w-75"></div>
						<p className="text-center mt-4">
							Already have an account?{" "}
							<Link
								className="text-orange fw-semibold"
								to={"/login"}
							>
								Login
							</Link>
						</p>
					</div>
					<div className="col-md-6">
						<RegisterImage className="p-5 img-fluid d-none d-md-block" />
					</div>
				</div>
			</div>
			<Footer />
		</>
	);
};

export default Register;
