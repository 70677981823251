export const slugify = (str) =>
	str
		.toLowerCase()
		.trim()
		.replace(/[^\w\s-]/g, "")
		.replace(/[\s_-]+/g, "-")
		.replace(/^-+|-+$/g, "");

export const getWishlist = async () => {
	return JSON.parse(localStorage.getItem("wishlist"));
};

export const addWishlist = async (data) => {
	let wishlist = JSON.parse(localStorage.getItem("wishlist"));
	if (wishlist) {
		wishlist.push(data);
		localStorage.setItem("wishlist", JSON.stringify(wishlist));
	} else {
		localStorage.setItem("wishlist", JSON.stringify([data]));
	}
};

export const removeWishlist = async (data) => {
	let wishlist = JSON.parse(localStorage.getItem("wishlist"));
	if (wishlist) {
		let updatedWishlist = wishlist.filter((item) => item.id !== data.id);
		localStorage.setItem("wishlist", JSON.stringify(updatedWishlist));
	}
}
