import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { slugify } from "../config/global/utils";
import NewLaunchProductCard from "./NewLaunchProductCard";
import ProductCard from "./ProductCard";
import '../assets/customHome.css'

const HomeSubCategoryProductList = ({ id, catName, products, viewAll=true, newLaunch=false, setModalData }) => {
	const scrollRef = useRef(null);
	const [scrollX, setscrollX] = useState(0);
	const [scrolEnd, setscrolEnd] = useState(false);

	console.log(scrollRef);
	const slide = (shift) => {
		scrollRef.current.scrollLeft += shift;
		setscrollX(scrollX + shift);

		if (
			Math.floor(
				scrollRef.current.scrollWidth - scrollRef.current.scrollLeft
			) <= scrollRef.current.offsetWidth
		) {
			setscrolEnd(true);
		} else {
			setscrolEnd(false);
		}
	};

	const scrollCheck = () => {
		setscrollX(scrollRef.current.scrollLeft);
		if (
			Math.floor(
				scrollRef.current.scrollWidth - scrollRef.current.scrollLeft
			) <= scrollRef.current.offsetWidth
		) {
			setscrolEnd(true);
		} else {
			setscrolEnd(false);
		}
	};

	return (
		<div className="d-flex flex-column m-2 my-4 p-4 subcatRow">
			<div className="d-flex justify-content-center align-items-center">
				<div className="h2 style-heading section-heading uppercase">{catName}</div>
			</div>
			<hr className="divider" />
			<div className="d-flex position-relative align-items-center justify-content-center">
				{scrollX !== 0 && (
					<button
						className="prev scroll-btn"
						onClick={() => slide(-450)}
					>
						<i className="bi bi-caret-left-fill"></i>
					</button>
				)}
				<div
					className="overflow-x-scroll d-flex hide-scrollbar gap-2"
					ref={scrollRef}
					onScroll={scrollCheck}
				>
					{products &&
						(newLaunch
							? products.map((product, index) => (
									<NewLaunchProductCard
										key={index}
										name={product.name}
										description={product.description}
										images={product.images}
										setModalData={setModalData}
									/>
							  ))
							: products.map((product, index) => (
									<ProductCard
										data={product}
										key={index}
										cat={catName}
									/>
							  )))}
				</div>
				{!scrolEnd && (
					<button
						className="next scroll-btn"
						onClick={() => slide(+450)}
					>
						<i className="bi bi-caret-right-fill"></i>
					</button>
				)}
			</div>
			{viewAll && <Link to={`/search/sub-category/${id}/${slugify(catName)}`} className="btn btn-secondary view-all">View All</Link>}
		</div>
	);
};

export default HomeSubCategoryProductList;
