import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "../config/hooks/useAuth";
import { ReactComponent as DeleteIcon } from "../static/img/delete.svg";

const Cart = () => {

	const {cart, cartDispatch} = useAuth();
	const [total, setTotal] = useState(0);

	const navigate = useNavigate();

	const removeItem = (item) => {
		cartDispatch({ type: "REMOVE_FROM_CART", payload: item });
	}

	const updateCart = (item, action) => {
		if (action === "inc") {
			item.quantity += 1;
		} else if (action === "dec") {
			item.quantity -= 1;
		}
		if (item.quantity === 0) {
			removeItem(item);
		} else {
			cartDispatch({ type: "UPDATE_CART", payload: item });
		}
	}

	useEffect(() => {
		let total = 0;
		cart.forEach((item) => {
			total += item.price * item.quantity;
		});
		setTotal(total);
	}, [cart]);

	return (
		<div
			className="offcanvas offcanvas-end"
			tabIndex="-1"
			id="cartOffCanvas"
			aria-labelledby="cartOffCanvasLabel"
		>
			<div className="offcanvas-header">
				<h5 className="offcanvas-title" id="cartOffCanvasLabel">
					Shopping Cart
				</h5>
				<button
					type="button"
					className="btn-close text-reset"
					data-bs-dismiss="offcanvas"
					aria-label="Close"
				></button>
			</div>
			<div className="offcanvas-body d-flex flex-column justify-content-between p-0">
				<div className="container-fluid overflow-y-scroll">
					{
						cart.length > 0 ? (
							cart.map((item) => (
								<div className="row py-1 border-bottom" key={item.id}>
									<div className="col-3 d-flex justify-content-center align-items-center">
										<img
											src={item.image}
											alt="img"
											className="img-fluid rounded"
										/>
									</div>
									<div className="col-6 d-flex flex-column">
										<div>{item.name}</div>
										<div className="d-flex flex-column justify-content-between text-smaller">
											<div className="d-flex gap-2">
												<div>Size: {item.size}</div>
												<div>Colour: {item.color}</div>
											</div>
										</div>
										<div className="d-flex border rounded col-6">
											<button className="btn btn-sm flex-1" onClick={() => updateCart(item, "dec")}>
												{" "}
												-{" "}
											</button>
											<input
												type="text"
												readOnly
												id=""
												value={item.quantity}
												className="text-center flex-1"
												style={{ width: "25px", border: "0px" }}
											/>
											<button className="btn btn-sm flex-1" onClick={() => updateCart(item, "inc")}>
												{" "}
												+{" "}
											</button>
										</div>
									</div>
									<div className="col-3 d-flex flex-column justify-content-between">
										<p>₹{item.price}</p>
										<div className="cursor-pointer" onClick={() => removeItem(item)}>
											<DeleteIcon style={{height: "20px", width: "20px", marginBottom: "5px"}} />
										</div>
									</div>
								</div>
							))
						) : (
							<div className="text-center py-5">
								<h5 className="text-brown">No items in cart</h5>
							</div>
						)
					}
				</div>
				<div className="border-top p-3 d-flex flex-column">
					<div className="d-flex justify-content-between text-base font-medium text-dark">
						<div>Subtotal</div>
						<div>₹{total}</div>
					</div>
					<p className="pt-0 text-smaller">
						Shipping and taxes calculated at checkout.
					</p>
					<div className="mt-2 d-grid">
						<div
							className="btn btn-tertiary btn-lg btn-block"
							onClick={() => navigate("/checkout")}
							data-bs-dismiss="offcanvas"
							aria-label="Close"
							>
							Checkout
						</div>
					</div>
					<div className="mt-3 d-flex justify-content-center text-smaller text-center">
						<div>
							or{" "}
							<span
								className="text-primary cursor-pointer"
								data-bs-dismiss="offcanvas"
							>
								Continue Shopping
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Cart;
