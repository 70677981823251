import React from "react";
import { Link } from "react-router-dom";
import { slugify } from "../config/global/utils";
import useAuth from "../config/hooks/useAuth";

const ProductCard = ({ data, cat }) => {
	const { wishlistDispatch } = useAuth();

	const addToWishList = (e) => {
		e.stopPropagation();
		wishlistDispatch({
			type: "ADD_TO_WISHLIST",
			payload: {
				id: data.id,
				name: data.name,
				price: data.price,
				image: data.image_1,
			},
		});
		e.preventDefault();
	};

	return (
		<Link to={`/${slugify(cat)}/${data.id}/${slugify(data.name)}`}>
			<div className="d-flex flex-column">
				<div className="card product-card">
					{data.discount > 0 && (
						<div className="discount-badge">
							<span className="badge">-{data.discount}%</span>
						</div>
					)}
					<div className="img-container">
						<img
							src={data.image_1}
							className="img-fluid product-img"
							alt="..."
						/>
					</div>
					<div className="card-body pt-2 position-relative">
						<div
							className="wishlist-div px-3 py-1"
							onClick={(e) => addToWishList(e)}
						>
							<i className="bi bi-heart"></i>
						</div>
						<h5 className="card-title mb-0 product-cardName">{data.name}</h5>
						<h5 className="card-text mb-1 d-flex gap-3">
							{data.discount > 0 ? (
								<>
									&#8377;
									{Math.floor(
										data.price -
											(data.price * data.discount) / 100
									)}{" "}
									<span className="text-decoration-line-through h6 pl-4 align-self-end mb-0">
										&#8377; {data.price}
									</span>{" "}
								</>
							) : (
								<span>&#8377; {data.price}</span>
							)}
						</h5>
						<div className="card-text">Sizes: {data.sizes}</div>
						<div className="card-text d-flex gap-2">
							Colors:
							<div className="d-flex align-items-center">
								{data.colors.map((color, index) => (
									<div
										key={index}
										className="color-div"
										style={{ backgroundColor: color }}
									></div>
								))}
							</div>
						</div>
					</div>
				</div>
			</div>
		</Link>
	);
};

export default ProductCard;
