import axios from "axios";
import React, { useEffect, useState } from "react";
import CategoryNavbar from "../components/CategoryNavbar";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import { GLOBAL_URL } from "../config/global/constant";

const ExchangePolicy = () => {
    const [exchangePolicy, setExchangePolicy] = useState("");

    const getExchangePolicy = async () => {
        axios.get(`${GLOBAL_URL}/general/exchange-policy`, {
            headers: {
                "Content-Type": "application/json",
            },
        })
        .then((res) => {
            console.log(res.data.data);
            setExchangePolicy(res.data.data);
        })
        .catch((err) => {
            console.log(err);
        })
    };

    useEffect(() => {
        getExchangePolicy();
    }, []);

	return (
		<>
			<Navbar />
			<CategoryNavbar />
			<div className="container py-4">
				<div
					dangerouslySetInnerHTML={{ __html: exchangePolicy.content }}
				></div>
			</div>
			<Footer />
		</>
	);
};

export default ExchangePolicy;
