import React from "react";
import { Link } from "react-router-dom";
import Logo from "../static/img/logo-wbg.png";

const Footer = () => {
	return (
		<div className="">
			<footer className="bg-primary text-center text-lg-start text-brown mt-4">
				<div className="container-fluid p-4 px-1">
					<div className="row m-4">
						<div className="col-lg-4 col-md-6 mb-4 mb-md-0">
							<div className=" shadow-1-strong d-flex align-items-center justify-content-center mb-4 mx-auto">
								<img
									src={Logo}
									height="70"
									alt=""
									loading="lazy"
								/>
							</div>

							<ul className="list-unstyled d-flex flex-row justify-content-center">
								{/* <li>
									<a className="text-brown p-2" href="#!">
										<i className="fab fa-facebook-square social-icon"></i>
									</a>
								</li> */}
								<li>

									<a className="text-brown p-2" href="https://instagram.com/nawabii.in?igshid=ZDdkNTZiNTM" target={"_blank"} rel="noreferrer">
										<i className="fab fa-instagram social-icon"></i>
									</a>
								</li>
								{/* <li>
									<a className="text-brown p-2" href="#!">
										<i className="fab fa-youtube social-icon"></i>
									</a>
								</li> */}
							</ul>
						</div>
						<div className="col-lg-2 col-md-6 mb-4 mb-md-0">
							<h5 className="text-uppercase mb-4">
								About Nawabii
							</h5>

							<ul className="list-unstyled">
								<li className="mb-2">
									<Link
										to={"/about-us"}
										className="text-brown link-hover"
									>
										About Us
									</Link>
								</li>
								<li className="mb-2">
									<Link
										to={"/contact-us"}
										className="text-brown link-hover"
									>
										Contact Us
									</Link>
								</li>
								<li className="mb-2">
									<Link to={"/faqs"} className="text-brown link-hover">
										FAQs
									</Link>
								</li>
							</ul>
						</div>
						<div className="col-lg-2 col-md-6 mb-4 mb-md-0">
							<h5 className="text-uppercase mb-4">Policies</h5>

							<ul className="list-unstyled">
								<li className="mb-2">
									<Link
										to={"/terms-and-conditions"}
										className="text-brown link-hover"
									>
										Terms & Conditions
									</Link>
								</li>
								<li className="mb-2">
									<Link
										to={"/privacy-policy"}
										className="text-brown link-hover"
									>
										Privacy Policy
									</Link>
								</li>
								<li className="mb-2">
									<Link
										to={"/shipping-policy"}
										className="text-brown link-hover"
									>
										Shipping Policy
									</Link>
								</li>
								<li className="mb-2">
									<Link
										to={"/return-policy"}
										className="text-brown link-hover"
									>
										Return/Refund Policy
									</Link>
								</li>
								<li className="mb-2">
									<Link
										to={"/exchange-policy"}
										className="text-brown link-hover"
									>
										Exchange Policy
									</Link>
								</li>
							</ul>
						</div>
						<div className="col-lg-4 col-md-6 mb-4 mb-md-0">
							<h5 className="text-uppercase mb-4">Contact</h5>

							<ul className="list-unstyled">
								<li>
									<p className="text-capitalize">
										<i className="fas fa-map-marker-alt pe-2"></i>
										NAWABII, IIIrd floor,House No 423, near
										AP Palace, Hind Nagar, Sector C1, LDA
										Colony, Lucknow, Uttar Pradesh 226012
										India
									</p>
								</li>
								<li>
									<p>
										<a
											href="tel:+918400100694"
											className="text-decoration-none"
										>
											<i className="fas fa-phone pe-2"></i>
											+91 8400100694 (Sales Enquiry)
										</a>
									</p>
								</li>
								<li>
									<p>
										<a
											href="tel:+918400100694"
											className="text-decoration-none"
										>
											<i className="fas fa-phone pe-2"></i>
											+91 8400400962 (Customer Care - 10 AM to 6 PM)
										</a>
									</p>
								</li>
								<li>
									<p>
										<a
											href="tel:+918400100694"
											className="text-decoration-none"
										>
											<i className="fas fa-phone pe-2"></i>
											+91 8400100662 (Order Query)
										</a>
									</p>
								</li>
								<li>
									<p>
										<i className="fas fa-envelope pe-2 mb-0"></i>
										nawabiidotin@gmail.com
									</p>
								</li>
							</ul>
						</div>
					</div>
				</div>
				<div
					className="text-center p-3 bg-tertiary text-light"
					style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}
				>
					© 2022 Copyright: <span className="fw-bold">Nawabii</span>
				</div>
			</footer>
		</div>
	);
};

export default Footer;
