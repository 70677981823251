import '../assets/shopByCategory.css'
import { Link } from "react-router-dom";
import { slugify } from "../config/global/utils";
import useAuth from "../config/hooks/useAuth";

export function ShopCategories({category}) {
    let imageMedia ;
    const categoryName = category.name.replace(`'s Collection`,'');
    imageMedia = '../media/category/nawabiiMan.png';
    return(
        <div className="category-card rounded text-center">
            <Link to={`/search/category/${category.id}/${slugify(category.name)}`}>
                <div className="card-media">
                    <img src={require(`../media/category/${categoryName=='Men'?'nawabiiMan.png':'nawabiiWomen.png'}`)} className="category-image"/>
                </div>
                <div className="card-content bg-tertiary">
                    {categoryName}
                </div>
            </Link>
        </div>
    )
}

function ShopByCategory({ categories }) {
	console.log(categories);
	return (
		<div className="d-flex flex-column m-2 my-4 p-4 subcatRow">
			<div className="d-flex justify-content-center align-items-center">
				<div className="h2 section-heading uppercase">
					Shop By Category
				</div>
			</div>
            <hr className="divider"/>
            <div className="d-flex category-card-wrapper position-relative align-items-center gap-15 justify-content-center grid grid-2 m-auto">
                {categories.map(item => <ShopCategories category={item} />) }
            </div>
		</div>
	);
}

export default ShopByCategory;
