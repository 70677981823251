import axios from "axios";
import React, { useState } from "react";
import { toast } from "react-toastify";
import CategoryNavbar from "../components/CategoryNavbar";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import { GLOBAL_URL } from "../config/global/constant";

const Contact = () => {
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [phone, setPhone] = useState("");
	const [subject, setSubject] = useState("");
	const [message, setMessage] = useState("");
	const [loading, setLoading] = useState(false);

	const sendMessage = async (e) => {
		e.preventDefault();
        if (!name) {
            toast.error("Name is required");
            return;
        }
        if (!email) {
            toast.error("Email is required");
            return;
        }
        if (!phone) {
            toast.error("Phone number is required");
            return;
        }
        if (phone.length < 10 || phone.length > 10) {
            toast.error("Phone number must be 10 digits");
            return;
        }
        if (!subject) {
            toast.error("Subject is required");
            return;
        }
        if (!message) {
            toast.error("Message is required");
            return;
        }
		setLoading(true);
		await axios
			.post(
				`${GLOBAL_URL}/general/contact-us`,
				{
					name: name,
					email: email,
					phone_number: phone,
					subject: subject,
					message: message,
				},
				{
					headers: {
						"Content-Type": "application/json",
					},
				}
			)
			.then((res) => {
				console.log(res.data);
				toast.success("Message sent successfully");
			})
			.catch((err) => {
				console.log(err);
				toast.error("Message failed to send");
			})
			.finally(() => {
				setLoading(false);
			});
	};

	return (
		<>
			<Navbar />
			<CategoryNavbar />
			<div className="container p-4">
				<div className="row no-gutters mb-5">
					<div className="col-md-7">
						<div className="contact-wrap w-100">
							<h1 className="mb-4">Contact Us</h1>
							<div className="row">
								<div className="col-md-6 mb-3">
									<div className="form-group">
										<label className="label" htmlFor="name">
											Full Name
										</label>
										<input
											type="text"
											className="form-control"
											name="name"
											id="name"
											placeholder="Name"
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
										/>
									</div>
								</div>
								<div className="col-md-6 mb-3">
									<div className="form-group">
										<label
											className="label"
											htmlFor="email"
										>
											Phone Number
										</label>
										<input
											type="tel"
											className="form-control"
											name="phone"
											id="phone"
											placeholder="Phone Number"
                                            maxLength={10}
                                            minLength={10}
                                            value={phone}
                                            onChange={(e) => setPhone(e.target.value)}
										/>
									</div>
								</div>
								<div className="col-md-12 mb-3">
									<div className="form-group">
										<label
											className="label"
											htmlFor="email"
										>
											Email
										</label>
										<input
											type="text"
											className="form-control"
											name="email"
											id="email"
											placeholder="Email"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
										/>
									</div>
								</div>
								<div className="col-md-12 mb-3">
									<div className="form-group">
										<label
											className="label"
											htmlFor="subject"
										>
											Subject
										</label>
										<input
											type="text"
											className="form-control"
											name="subject"
											id="subject"
											placeholder="Subject"
                                            value={subject}
                                            onChange={(e) => setSubject(e.target.value)}
										/>
									</div>
								</div>
								<div className="col-md-12 mb-3">
									<div className="form-group">
										<label className="label" htmlFor="#">
											Message
										</label>
										<textarea
											name="message"
											className="form-control"
											id="message"
											cols="30"
											rows="4"
											placeholder="Message"
                                            value={message}
                                            onChange={(e) => setMessage(e.target.value)}
										></textarea>
									</div>
								</div>
								<div className="col-md-12 mb-3">
									<div className="form-group">
										<button
											className="btn btn-tertiary"
											onClick={(e) => sendMessage(e)}
										>
											{loading ? (
												<span
													className="spinner-border spinner-border-sm"
													role="status"
													aria-hidden="true"
												></span>
											) : (
												"Send Message"
											)}
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="col-md-5 d-flex align-items-stretch">
						<iframe
							src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d227822.55035758688!2d80.8024265169638!3d26.848622993473228!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x399bfd991f32b16b%3A0x93ccba8909978be7!2sLucknow%2C%20Uttar%20Pradesh!5e0!3m2!1sen!2sin!4v1673182880612!5m2!1sen!2sin"
							width="600"
							height="450"
							style={{ border: "0" }}
							allowfullscreen=""
							loading="lazy"
							referrerpolicy="no-referrer-when-downgrade"
							title="location_map"
						></iframe>
					</div>
				</div>
			</div>
			<Footer />
		</>
	);
};

export default Contact;
