import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import CategoryNavbar from "../components/CategoryNavbar";
import Footer from "../components/Footer";
import HomeSubCategoryProductList from "../components/HomeSubCategoryProductList";
import Navbar from "../components/Navbar";
import Spinner from "../components/Spinner";
import ZoomedProductImages from "../components/ZoomedProductImages";
import { GLOBAL_URL } from "../config/global/constant";
import useAuth from "../config/hooks/useAuth";
import '../assets/productPage.css'

const ProductDetail = () => {
	const { id } = useParams();

	const sizes = ["XS", "S", "M", "L", "XL", "XXL", "3XL", "4XL"];
	const { wishlistDispatch, wishlist, cartDispatch, cart } = useAuth();
	const navigate = useNavigate();

	const [product, setProduct] = useState(null);
	const [loading, setLoading] = useState(true);
	const [quantity, setQuantity] = useState(1);
	const [selectedSize, setSelectedSize] = useState(null);
	const [selectedColor, setSelectedColor] = useState(null);
	const [images, setImages] = useState([]);
	const [currentImage, setCurrentImage] = useState(null);
	const [favorite, setFavorite] = useState(false);
	const [similarProducts, setSimilarProducts] = useState([]);
	const [pincode, setPincode] = useState("");
	const [pincodeMessage, setPincodeMessage] = useState({
		success: null,
		message: "",
	});
	const [stock, setStock] = useState(null);
	const [availableSizes, setAvailableSizes] = useState([]);
	const [itemInCart, setItemInCart] = useState(false);
	const [showZoomedImages, setShowZoomedImages] = useState(false);
	const [imageIndex, setImageIndex] = useState(0);

	const getProduct = async () => {
		setLoading(true);
		await axios
			.get(`${GLOBAL_URL}/product/product/${id}`)
			.then((res) => {
				console.log(res.data.data);
				setProduct(res.data.data);
				setSelectedColor(res.data.data.colors[0]);
				setImages(res.data.data.colors[0].images);
				setStock(res.data.data.colors[0].stock);
				setAvailableSizes(res.data.data.colors[0].sizes);
				setCurrentImage(res.data.data.colors[0].images[0]);
				setSimilarProducts(res.data.data.similar_products);
				setFavorite(
					wishlist?.findIndex(
						(item) => item.id === res.data.data?.id
					) !== -1
				);
				document.title = res.data.data?.name;
			})
			.finally(() => {
				setLoading(false);
				if (localStorage.getItem("pincode")) {
					setPincode(localStorage.getItem("pincode"));
					checkPincode();
				}
			});
	};

	const checkPincode = () => {
		if (!product.available_pincodes.includes(pincode)) {
			setPincodeMessage({
				success: true,
				message: "Delivery available",
			});
			localStorage.setItem("pincode", pincode)
		} else {
			setPincodeMessage({
				success: false,
				message: "Delivery not available",
			});
		}
	};

	const addToWishList = () => {
		wishlistDispatch({
			type: "ADD_TO_WISHLIST",
			payload: {
				id: product?.id,
				name: product?.name,
				price: product?.price,
				image: selectedColor?.images[0],
			},
		});
	};

	const removeWishlist = () => {
		wishlistDispatch({
			type: "REMOVE_FROM_WISHLIST",
			payload: product?.id,
		});
	};

	const addToCart = () => {
		if (cart?.findIndex((item) => item.id === product?.id) !== -1) {
			setItemInCart(true);
			setQuantity(
				cart[cart.findIndex((item) => item.id === product?.id)].quantity
			);
			return;
		}
		if (availableSizes !== null && selectedSize === null) {
			toast.warning("Please select size");
			return;
		}
		if (selectedColor === null) {
			toast.warning("Please select color");
			return;
		}
		if (stock === 0) {
			toast.warning("Out of stock");
			return;
		}
		if (pincodeMessage.success === null) {
			toast.warning("Please check pincode");
			return;
		} else if (pincodeMessage.success === false) {
			toast.warning("Delivery not available");
			return;
		}
		if (cart?.findIndex((item) => item.id === product?.id) === -1) {
			cartDispatch({
				type: "ADD_TO_CART",
				payload: {
					id: product?.id,
					name: product?.name,
					price:
						product?.discount > 0
							? Math.floor(
									product.price -
										(product.price * product.discount) / 100
							  )
							: product?.price,
					priceSaved:
						product?.discount > 0
							? Math.floor(
									product.price -
										(product.price -
											(product.price * product.discount) /
												100)
							  )
							: 0,
					image: selectedColor?.images[0],
					quantity,
					size: selectedSize,
					color: selectedColor?.name,
					colorId: selectedColor?.id,
				},
			});
		}
	};

	const buyNow = () => {
		if (
			availableSizes &&
			availableSizes.length > 0 &&
			selectedSize === null
		) {
			toast.warning("Please select size");
			return;
		}
		if (selectedColor === null) {
			toast.warning("Please select color");
			return;
		}
		if (stock === 0) {
			toast.warning("Out of stock");
			return;
		}
		if (pincodeMessage.success === null) {
			toast.warning("Please check pincode");
			return;
		} else if (pincodeMessage.success === false) {
			toast.warning("Delivery not available");
			return;
		}
		if (cart?.findIndex((item) => item.id === product?.id) === -1) {
			cartDispatch({
				type: "ADD_TO_CART",
				payload: {
					id: product?.id,
					name: product?.name,
					price:
						product?.discount > 0
							? Math.floor(
									product.price -
										(product.price * product.discount) / 100
							  )
							: product?.price,
					image: selectedColor?.images[0],
					quantity,
					size: selectedSize,
					color: selectedColor?.name,
					colorId: selectedColor?.id,
				},
			});
		}
		navigate("/checkout");
	};

	useEffect(() => {
		getProduct();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);

	useEffect(() => {
		if (
			favorite &&
			wishlist?.findIndex((item) => item.id === product?.id) === -1
		) {
			addToWishList();
		} else {
			removeWishlist();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [favorite]);

	useEffect(() => {
		if (cart.findIndex((item) => item.id === product?.id) !== -1) {
			setQuantity(
				cart[cart.findIndex((item) => item.id === product?.id)].quantity
			);
			setItemInCart(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [cart]);

	useEffect(() => {
		// make the images slide automatically
		const interval = setInterval(() => {
			if (imageIndex < images.length - 1) {
				setImageIndex(imageIndex + 1);
			} else {
				setImageIndex(0);
			}
		}, 3000);
		return () => clearInterval(interval);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [imageIndex]);

	if (loading) {
		return <Spinner />;
	}

	return (
		<>
			<Navbar />
			<CategoryNavbar />
			<div className="product-body d-flex h-100 flex-wrap">
				<div className="flex-3 mw-100 p-4">
					<div className="position-sticky media-container-sticky bottom-0">
						<div
							id="image-container"
							className="bg-white rounded border-slate-700 select-none position-relative"
							style={{
								boxShadow: "black 0px 0px 15px -10px",
								height: "calc(100vh - 200px)",
							}}
						>
							<div className="w-100 h-100 d-flex justify-content-center align-items-center p-3 rounded">
								<img
									className="object-contain h-100 w-100 rounded"
									src={images[imageIndex]}
									alt="currentImage"
									onClick={() => {
										setShowZoomedImages(true);
									}}
								/>
								<div
									style={{
										position: "absolute",
										top: "1rem",
										right: "1rem",
										cursor: "pointer",
										backgroundColor: "#fff",
										padding: "0.5rem",
										borderRadius: "50%",
										border: "0.5px solid #aaa",
									}}
									onClick={() => {
										setFavorite((favorite) => !favorite);
									}}
								>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										fill={favorite ? "#f00" : "none"}
										viewBox="0 0 24 24"
										stroke="currentColor"
										aria-hidden="true"
										className="h-10 wishlist-icon"
										style={{
											color: favorite ? "#f00" : "#000",
										}}
									>
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											strokeWidth="2"
											d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
										></path>
									</svg>
								</div>
							</div>
						</div>
						<div className="d-flex overflow-scroll gap-2 media-thumbnail justify-content-between h-24 w-100 p-1 border border-opacity-50 shadow-sm rounded my-1">
							{images.map((image, index) => (
								<div
									className={`d-flex media-thumbnail-item justify-content-center align-items-center p-1 h-100 w-20 ${
										imageIndex === index &&
										"border border-secondary rounded"
									}`}
									key={index}
									onClick={() => {
										setImageIndex(index);
									}}
								>
									<img
										className={`object-cover h-100 rounded`}
										src={image}
										alt="img"
										style={{
											cursor: "pointer",
											width: "100%",
											height: "100%",
										}}
									/>
								</div>
							))}
						</div>
					</div>
				</div>
				<div className="flex-4 m-4">
					<nav aria-label="breadcrumb">
						<ol className="breadcrumb">
							<li className="breadcrumb-item">
								{product?.category}
							</li>
							<li className="breadcrumb-item">
								{product?.sub_category}
							</li>
						</ol>
					</nav>
					<div className="d-flex align-items-end gap-2">
						<div className="fs-2 product-title">
							{product?.name}
						</div>
					</div>
					{/* <div className="d-flex gap-2 fs-6 text-light-orange">
						<i
							class={`bi ${
								product?.rating >= 1
									? "bi-star-fill"
									: "bi-star"
							}`}
						></i>
						<i
							class={`bi ${
								product?.rating >= 2
									? "bi-star-fill"
									: "bi-star"
							}`}
						></i>
						<i
							class={`bi ${
								product?.rating >= 3
									? "bi-star-fill"
									: "bi-star"
							}`}
						></i>
						<i
							class={`bi ${
								product?.rating >= 4
									? "bi-star-fill"
									: "bi-star"
							}`}
						></i>
						<i
							class={`bi ${
								product?.rating >= 5
									? "bi-star-fill"
									: "bi-star"
							}`}
						></i>
					</div> */}
					<div>
						{stock === 0 || stock === null ? (
							<span className="text-smaller bg-danger text-white p-1 px-2 rounded">
								Out of Stock
							</span>
						) : (
							stock < 5 && (
								<span className="text-smaller bg-orange text-white p-1 px-2 rounded">
									Hurry up, Only few pieces left
								</span>
							)
						)}
					</div>
					{product.discount > 0 ? (
						<div className="h3 mt-2">
							<span className="h4 mt-2 text-danger">
								- {product.discount}%{" "}
							</span>
							<span className="h3 mt-2">
								&#8377;
								{Math.floor(
									product.price -
										(product.price * product.discount) / 100
								)}{" "}
							</span>
							<span className="h6 mt-2 text-muted">MRP: </span>
							<span className="h6 mt-2 text-muted">
								<s>&#8377; {product.price}</s>
							</span>
						</div>
					) : (
						<span className="h3 mt-2">&#8377; {product.price}</span>
					)}
					<hr />
					<div className="w-100 d-flex align-items-center mt-4">
						<div className="w-25 text-uppercase h6">Colours</div>
						<div className="w-75">
							<div className="d-flex align-items-center">
								{product?.colors?.map((colour, index) => (
									<div
										className="d-flex align-items-center gap-2"
										key={index}
										onClick={() => {
											setSelectedColor(colour);
											setImages(colour.images);
											setCurrentImage(colour.images[0]);
											setStock(colour.stock);
											setAvailableSizes(colour.sizes);
										}}
									>
										<div
											className={`colorImageBtn ${
												selectedColor.id ===
													colour.id &&
												"colorImageBtn-selected"
											} position-relative`}
										>
											<img
												src={colour.thumbnail}
												alt="thumbnail"
											/>
											<p className="position-absolute">
												{colour.name}
											</p>
										</div>
									</div>
								))}
							</div>
						</div>
					</div>
					{availableSizes?.length > 0 && (
						<div className="w-100 d-flex align-items-center mt-4">
							<div className="w-25 text-uppercase h6">Sizes</div>
							<div className="w-75">
								<div className="d-flex align-items-center flex-wrap">
									{sizes?.map((size, index) => (
										<div
											className="d-flex align-items-center gap-2"
											key={index}
										>
											<div
												className={`btn sizeBtn ${
													selectedSize === size &&
													"sizeBtn-selected"
												} ${
													!availableSizes.includes(
														size
													) && " sizeBtn-disabled"
												}`}
												onClick={() => {
													if (
														availableSizes.includes(
															size
														)
													) {
														setSelectedSize(size);
													}
												}}
											>
												{size}
											</div>
										</div>
									))}
								</div>
							</div>
						</div>
					)}
					<div className="w-100 d-flex mt-4 pt-3">
						<div className="w-25 text-uppercase h6 pt-2">
							Check Pincode
						</div>
						<div className="w-75 d-flex flex-column">
							<div className="d-flex gap-2">
								<input
									className="form-control w-30 w-sm-60"
									type="text"
									placeholder="Enter Pincode"
									value={pincode}
									onChange={(e) => setPincode(e.target.value)}
									maxLength={6}
									minLength={6}
									pattern="[0-9]{6}"
								/>
								<button
									className="btn btn-tertiary"
									type="submit"
									onClick={() => checkPincode()}
								>
									Check
								</button>
							</div>
							<p
								className={`${
									pincodeMessage?.success === true
										? "text-success"
										: "text-danger"
								}`}
							>
								{pincodeMessage?.message}
							</p>
						</div>
					</div>
					<div className="w-100 d-flex align-items-center mt-4">
						<div className="w-25 text-uppercase h6">Quantity</div>
						<div className="w-75 d-flex">
							<div className="d-flex align-items-center gap-2">
								<button
									className={`btn btn-creme ${
										quantity === 1 ? "btn-disabled" : ""
									}`}
									onClick={() => {
										if (quantity > 1) {
											setQuantity(quantity - 1);
										}
									}}
									disabled={quantity === 1}
								>
									<span className="h3">-</span>
								</button>
								<p className="w-10 mb-0 text-center h4">
									{quantity}
								</p>
								<button
									className="btn btn-creme"
									onClick={() => setQuantity(quantity + 1)}
									disabled={
										quantity === 10 ||
										quantity >= product?.inventory
									}
								>
									<span className="h3">+</span>
								</button>
							</div>
						</div>
					</div>
					<div className="w-100 d-flex buy-buttons align-items-center mt-3 pt-3 gap-4">
						<div className="w-50 d-flex">
							{itemInCart ? (
								<button
									className="btn btn-creme w-100"
									data-bs-toggle="offcanvas"
									href="#cartOffCanvas"
									aria-controls="cartOffCanvas"
								>
									Go to Cart
								</button>
							) : (
								<button
									className="btn btn-creme w-100"
									onClick={() => addToCart()}
								>
									Add to Cart
								</button>
							)}
						</div>
						<div className="w-50 d-flex">
							<button
								className="btn btn-tertiary w-100"
								onClick={() => buyNow()}
							>
								Buy Now
							</button>
						</div>
					</div>
					<hr />
					<ul
						className="nav nav-pills mb-3 mt-3"
						id="pills-tab"
						role="tablist"
					>
						<li className="nav-item" role="presentation">
							<button
								className="nav-link active"
								id="pills-home-tab"
								data-bs-toggle="pill"
								data-bs-target="#pills-home"
								type="button"
								role="tab"
								aria-controls="pills-home"
								aria-selected="true"
							>
								Description
							</button>
						</li>
						<li className="nav-item" role="presentation">
							<button
								className="nav-link"
								id="pills-profile-tab"
								data-bs-toggle="pill"
								data-bs-target="#pills-profile"
								type="button"
								role="tab"
								aria-controls="pills-profile"
								aria-selected="false"
							>
								Size Chart
							</button>
						</li>
						<li className="nav-item" role="presentation">
							<button
								className="nav-link"
								id="pills-reivew-tab"
								data-bs-toggle="pill"
								data-bs-target="#pills-reivew"
								type="button"
								role="tab"
								aria-controls="pills-reivew"
								aria-selected="false"
							>
								Reviews
							</button>
						</li>
					</ul>
					<div className="tab-content" id="pills-tabContent">
						<div
							className="tab-pane fade show active"
							id="pills-home"
							role="tabpanel"
							aria-labelledby="pills-home-tab"
							tabIndex="0"
						>
							<div className="container">
								<div className="row">
									<div className="col-md-12">
										<p>{product?.description}</p>
									</div>
								</div>
								<div
									className="accordion"
									id="accordionExample"
								>
									<div className="accordion-item">
										<h2
											className="accordion-header"
											id="headingOne"
										>
											<button
												className="accordion-button"
												type="button"
												data-bs-toggle="collapse"
												data-bs-target="#collapseOne"
												aria-expanded="true"
												aria-controls="collapseOne"
											>
												Product Details
											</button>
										</h2>
										<div
											id="collapseOne"
											className="accordion-collapse collapse"
											aria-labelledby="headingOne"
											data-bs-parent="#accordionExample"
										>
											<div className="accordion-body">
												{product?.specifications?.map(
													(specification, index) => (
														<div
															className="row"
															key={index}
														>
															<div className="col-md-6">
																<p className="fw-bold">
																	{
																		specification?.name
																	}
																</p>
															</div>
															<div className="col-md-6">
																<p>
																	{specification?.value ===
																	false
																		? "No"
																		: specification?.value ===
																		  true
																		? "Yes"
																		: specification?.value}
																</p>
															</div>
														</div>
													)
												)}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div
							className="tab-pane fade"
							id="pills-profile"
							role="tabpanel"
							aria-labelledby="pills-profile-tab"
							tabIndex="0"
						>
							<div className="container">
								<div className="row">
									{product.size_chart ? (
										<div className="col-md-12 p-4 img-fluid">
											<img
												src={product.size_chart}
												className="img-fluid"
												alt="size_char"
											/>
										</div>
									) : (
										<p className="">
											Size chart not available
										</p>
									)}
								</div>
							</div>
						</div>
						<div
							className="tab-pane fade"
							id="pills-reivew"
							role="tabpanel"
							aria-labelledby="pills-reivew-tab"
							tabIndex="0"
						>
							<div className="container">
								<div className="row">
									<div className="col-md-12">
										{product?.reviews?.length > 0 ? (
											<div className="row">
												{product?.reviews?.map(
													(review, index) => (
														<div
															className="col-md-12"
															key={index}
														>
															<div className="card mb-3">
																<div className="card-body">
																	<div className="row">
																		<div className="col-md-2">
																			<img
																				src="https://png.pngtree.com/png-clipart/20210915/ourmid/pngtree-user-avatar-login-interface-abstract-blue-icon-png-image_3917504.jpg"
																				alt="user"
																				className="rounded-circle"
																				width="65"
																			/>
																		</div>
																		<div className="col-md-10">
																			<h5 className="mb-1 d-flex gap-4 align-items-center">
																				{
																					review?.name
																				}
																				<p className="mb-1 fs-6">
																				{
																					review?.rating
																				}
																				<i className="fa fa-star text-warning fs-6"></i>
																			</p>
																			</h5>
																			<p className="mb-1">
																				{
																					review?.comment
																				}
																			</p>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													)
												)}
											</div>
										) : (
											<p className="">
												No reviews available
											</p>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<HomeSubCategoryProductList
				catName={"Similar Products"}
				products={similarProducts}
				viewAll={false}
			/>
			<Footer />

			{showZoomedImages && (
				<ZoomedProductImages
					images={images}
					setShowZoomedImages={setShowZoomedImages}
				/>
			)}
		</>
	);
};

export default ProductDetail;
