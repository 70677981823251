import "../assets/parallax.css";
export default function Parallax({ image }) {
	return (
		<section className="parallax section d-flex flex-column m-5 my-4">
			<a id="parallaxLink" href="https://www.google.com" target="_blank">
				<img
					src={require(`../media/Parallax/${image}`)}
					id="parallaxImage"
				/>
                <div className="parallax-background"></div>
				<div className="text-content">
					<h3 className="parallaxHeading">
						Get Best Ethnic Collection
					</h3>
					<p className="parallaxDescription">
						Get Best products in effective price range.
					</p>
					<a
						href="https://www.example.com"
						title="ethnic-collection-link"
						target="_blank"
					>
						<button className="btn btn-secondary view-all">
							Shop Now
						</button>
					</a>
				</div>
			</a>
		</section>
	);
}
