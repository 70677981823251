import React, { useState } from "react";
import { Link, useNavigate,  } from "react-router-dom";
import { slugify } from "../config/global/utils";
import useAuth from "../config/hooks/useAuth";

const CategoryNavbar = () => {
	const { categoryData } = useAuth();
	const [search, setSearch] = useState("");
	const navigate = useNavigate();

	const onSubmit = (e) => {
		e.preventDefault();
		navigate(`/search/search/0/${search}`);
	};

	return (
		<div className="py-2 px-2 nav-category sticky-head bg-tertiary d-flex justify-content-between align-items-center w-100 flex-wrap">
			<div className="d-flex navbarCategories">
				<Link to={"/"} className="px-2 mb-0 cursor-pointer d-none d-sm-block link-hover">
					<div className="text-white">Home</div>
				</Link>
				{categoryData.map((item, index) => (
					<div
						className="px-2 mb-0 cursor-pointer dropdown "
						key={index}
					>
						<div
							onClick={() => navigate(`/search/category/${item.id}/${slugify(item.name)}`)}
							className="text-white dropdown-toggle"
							data-bs-toggle="dropdown"
							aria-expanded="false"
						>
							{item.name}
						</div>
						<div className="dropdown-menu mensSubCat">
							{item.sub_categories.map((subItem, subIndex) => (
								<Link
									to={`/search/sub-category/${subItem.id}/${slugify(subItem.name)}`}
									className="dropdown-item text-white link-hover"
									key={subIndex}
								>
									{subItem.name}
								</Link>
							))}
						</div>
					</div>
				))}
				<Link to={"/about-us"} className="px-2 mb-0 cursor-pointer d-none d-sm-block link-hover">
					<div className="text-white">About Us</div>
				</Link>
				<Link to={"/contact-us"} className="px-2 mb-0 cursor-pointer d-none d-sm-block link-hover">
					<div className="text-white">Contact Us</div>
				</Link>
				{/* <div className="px-2 mb-0 cursor-pointer">
					<div className="text-white">New Launches</div>
				</div> */}
			</div>
			<form
				className="d-flex mt-1"
				role="search"
				onSubmit={(e) => onSubmit(e)}
			>
				<input
					className="form-control navbarSeachInput"
					type="search"
					placeholder="Search"
					aria-label="Search"
					value={search}
					onChange={(e) => setSearch(e.target.value)}
				/>
				<button
					className="btn btn-primary navbarSeachBtn"
					type="submit"
				>
					<i className="bi bi-search text-brown"></i>
				</button>
			</form>
		</div>
	);
};

export default CategoryNavbar;
