import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import CategoryNavbar from "../components/CategoryNavbar";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import ProductCard from "../components/ProductCard";
import Spinner from "../components/Spinner";
import { GLOBAL_URL } from "../config/global/constant";

const SearchPage = () => {
	const { name, type, id } = useParams();
	const [products, setProducts] = useState([]);
	const [loading, setLoading] = useState(true);

    const generateUrl = () => {
        if (type === "category") {
            return `${GLOBAL_URL}/product/product/search?cat=${id}`;
        } else if (type === "sub-category") {
            return `${GLOBAL_URL}/product/product/search?sub_cat=${id}`;
        } else if (type === "search") {
            return `${GLOBAL_URL}/product/product/search?search=${name}`;
        }
    }

	const getProducts = async () => {
		setLoading(true);
		await axios
			.get(generateUrl(), {
				headers: {
					"Content-Type": "application/json",
				},
			})
			.then((res) => {
                console.log(res.data.data);
				setProducts(res.data.data);
			})
			.catch((err) => {
				console.log(err);
			})
			.finally(() => {
				setLoading(false);
			});
	};

    useEffect(() => {
        getProducts();
    }, [id, name, type]);

	return (
		<>
			<Navbar />
			<CategoryNavbar />
			<div className="container">
				<div className="row my-4 border-bottom pb-4">
					<div className="col-12 d-flex justify-content-between align-items-center">
						<h1 className="text-capitalize h2">
							{name.split("-").join(" ")}
						</h1>
						<div className="dropdown">
							<div
								className="dropdown-toggle fs-6"
								type="button"
								id="dropdownMenuButton1"
								data-bs-toggle="dropdown"
								aria-expanded="false"
							>
								Sort
							</div>
							<ul
								className="dropdown-menu"
								aria-labelledby="dropdownMenuButton1"
							>
								<li>
									<span className="dropdown-item">
										Newest
									</span>
								</li>
								<li>
									<span className="dropdown-item">
										Price: Low to High
									</span>
								</li>
								<li>
									<span className="dropdown-item">
										Price: High to Low
									</span>
								</li>
							</ul>
						</div>
					</div>
				</div>
                <div className="row">
                    {loading ? (
                        <Spinner bgClass={"bg-white"} />
                    ) : (
                        products.map((product, index) => (
                            <div className="col-6 col-md-6 col-lg-3 search-page" key={index}>
                                <ProductCard data={product} cat={name} />
                            </div>
                        ))
                    )}
                </div>
			</div>
			<Footer />
		</>
	);
};

export default SearchPage;
