import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { GLOBAL_URL } from "../config/global/constant";
import Empty from "../static/img/empty.svg";

const Orders = () => {
	const [orders, setOrders] = useState([]);
	const [reviewData, setReviewData] = useState({
		id: "",
		title: "",
		review: "",
		rating: 0,
	});
	const [exchangeData, setExchangeData] = useState({
		id: "",
		reason: "",
		description: "",
	});
	const [cancelData, setCancelData] = useState({
		id: "",
		reason: "",
	});

	const getOrders = async () => {
		await axios
			.get(`${GLOBAL_URL}/payment/billing`, {
				headers: {
					Authorization: `Token ${localStorage.getItem("token")}`,
				},
			})
			.then((res) => {
				setOrders(res.data.data);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const submitReview = async () => {
		await axios
			.post(
				`${GLOBAL_URL}/product/review`,
				{
					id: reviewData.id,
					title: reviewData.title,
					review: reviewData.review,
					rating: reviewData.rating,
				},
				{
					headers: {
						Authorization: `Token ${localStorage.getItem("token")}`,
					},
				}
			)
			.then((res) => {
				console.log(res.data);
				setReviewData({
					id: "",
					title: "",
					review: "",
					rating: 0,
				});
				toast.success("Review submitted successfully");
			})
			.catch((err) => {
				console.log(err);
				toast.error("Something went wrong");
			});
	};

	const sendExchangeRequest = async () => {
		await axios
			.post(
				`${GLOBAL_URL}/payment/exchange`,
				{
					id: exchangeData.id,
					reason: exchangeData.reason,
					desc: exchangeData.description,
				},
				{
					headers: {
						Authorization: `Token ${localStorage.getItem("token")}`,
					},
				}
			)
			.then((res) => {
				console.log(res.data.data);
				setExchangeData({
					id: "",
					reason: "",
					description: "",
				});
				toast.success("Exchange request sent successfully");
			})
			.catch((err) => {
				console.log(err);
				toast.error("Could not send exchange request");
			})
			.finally(() => {
				setExchangeData({
					id: "",
					reason: "",
					description: "",
				});
				getOrders();
			});
	};

	const sendCancelRequest = async () => {
		await axios
			.post(
				`${GLOBAL_URL}/payment/cancel-order`,
				{
					id: cancelData.id,
					reason: cancelData.reason,
				},
				{
					headers: {
						Authorization: `Token ${localStorage.getItem("token")}`,
					},
				}
			)
			.then((res) => {
				console.log(res.data.data);
				setCancelData({
					id: "",
					reason: "",
				});
				toast.success("Cancel request sent successfully");
			})
			.catch((err) => {
				console.log(err);
				toast.error("Could not send cancel request");
			})
			.finally(() => {
				setCancelData({
					id: "",
					reason: "",
				});
				getOrders();
			});
	};

	useEffect(() => {
		getOrders();
	}, []);

	return (
		<div className="row p-4">
			<div className="col-12">
				<p className="mb-0 fs-6 fw-bold">Orders</p>
				<hr />
			</div>
			{orders.length > 0 ? (
				orders.map((order, index) => (
					<div className="col-12 col-md-6 mt-2 px-0" key={index}>
						<div className="row py-1 border rounded position-relative mx-1">
							<div className="col-3 d-flex justify-content-center align-items-center">
								<img
									src={order.image}
									alt="img"
									className="rounded img-fluid"
									style={{ height: "90px" }}
								/>
							</div>
							<div className="col-6 d-flex flex-column">
								<div className="fw-bold fs-7 orders__product-name">
									{order.product}
								</div>
								<div className="d-flex flex-column justify-content-between text-smaller">
									<div className="d-flex flex-column">
										{order.size && (
											<div className="fs-8">
												Size: {order.size}
											</div>
										)}
										<div className="fs-8">
											Colour: {order.color}
										</div>
										<div className="fs-8">
											Quantity: {order.quantity}
										</div>
										<div className="fs-8">
											Payment Mode:{" "}
											{order.payment_mode ===
											"Cash On Delivery"
												? "COD"
												: "Online"}
										</div>
										<div className="fs-8">
											Order Id:{" "}
											<span className="fw-bold">
												{order.o_id}
											</span>
										</div>
									</div>
								</div>
							</div>
							<div className="col-3 d-flex flex-column justify-content-between align-items-between px-0">
								<div className="d-flex flex-column">
									<p className="fw-bold fs-6 mb-0">
										₹{order.amount * order.quantity}
									</p>
									<p className="mb-0">
										{order.tracking_status}
									</p>
								</div>
								<div className="d-flex flex-column">
									{order.tracking_status === "Delivered" &&
										new Date() <
											new Date(order.updated_on).setTime(
												new Date(
													order.updated_on
												).getTime() +
													3 * 24 * 60 * 60 * 1000
											) && (
											<div
												className="btn btn-tertiary fs-9 mb-1 me-1 text-nowrap"
												data-bs-toggle="modal"
												data-bs-target="#exhangeModal"
												onClick={() =>
													setExchangeData({
														id: order.id,
														reason: "",
														description: "",
													})
												}
											>
												Exchange
											</div>
										)}
									{order.tracking_status === "Ordered" && (
										<div
											className="btn btn-tertiary fs-9 mb-1 me-1 text-nowrap"
											data-bs-toggle="modal"
											data-bs-target="#cancelModal"
											onClick={() =>
												setCancelData({
													id: order.id,
													reason: "",
												})
											}
										>
											Cancel
										</div>
									)}
									<div
										className="btn btn-tertiary fs-9 mb-1 me-1 text-nowrap"
										data-bs-toggle="modal"
										data-bs-target="#writeReviewModal"
										onClick={() =>
											setReviewData({
												...reviewData,
												id: order.product_id,
											})
										}
									>
										Write a Review
									</div>
								</div>
							</div>
						</div>
					</div>
				))
			) : (
				<div className="d-flex flex-column justify-content-center align-items-center">
					<img
						src={Empty}
						alt="img"
						className="rounded img-fluid"
						style={{ objectFit: "cover", height: "100px" }}
					/>
					<p className="text-center mt-3">
						You have not placed any orders yet.
					</p>
				</div>
			)}
			{/* Modal to write a product review */}
			<div
				class="modal fade"
				id="writeReviewModal"
				tabindex="-1"
				aria-labelledby="writeReviewModalLabel"
				aria-hidden="true"
			>
				<div class="modal-dialog modal-dialog-centered">
					<div class="modal-content">
						<div class="modal-header">
							<h5 class="modal-title" id="writeReviewModalLabel">
								Write a Review
							</h5>
							<button
								type="button"
								class="btn-close"
								data-bs-dismiss="modal"
								aria-label="Close"
							></button>
						</div>
						<div class="modal-body d-flex flex-column justify-content-between gap-3">
							<div className="d-flex flex-column gap-1">
								<label for="reviewTitle">Title</label>
								<input
									type="text"
									className="form-control"
									id="reviewTitle"
									placeholder="Title"
									value={reviewData.title}
									onChange={(e) =>
										setReviewData({
											...reviewData,
											title: e.target.value,
										})
									}
								/>
							</div>
							<div className="d-flex flex-column gap-1">
								<label for="reviewBody">Review</label>
								<textarea
									className="form-control"
									id="reviewBody"
									rows="3"
									placeholder="Write your review here..."
									value={reviewData.review}
									onChange={(e) =>
										setReviewData({
											...reviewData,
											review: e.target.value,
										})
									}
								></textarea>
							</div>
							<div className="d-flex flex-column gap-1">
								<label for="reviewRating">Rating</label>
								<div className="d-flex gap-2 fs-4 text-warning">
									<i
										class={`bi cursor-pointer ${
											reviewData.rating >= 1
												? "bi-star-fill"
												: "bi-star"
										}`}
										onClick={() =>
											setReviewData({
												...reviewData,
												rating: 1,
											})
										}
									></i>
									<i
										class={`bi cursor-pointer ${
											reviewData.rating >= 2
												? "bi-star-fill"
												: "bi-star"
										}`}
										onClick={() =>
											setReviewData({
												...reviewData,
												rating: 2,
											})
										}
									></i>
									<i
										class={`bi cursor-pointer ${
											reviewData.rating >= 3
												? "bi-star-fill"
												: "bi-star"
										}`}
										onClick={() =>
											setReviewData({
												...reviewData,
												rating: 3,
											})
										}
									></i>
									<i
										class={`bi cursor-pointer ${
											reviewData.rating >= 4
												? "bi-star-fill"
												: "bi-star"
										}`}
										onClick={() =>
											setReviewData({
												...reviewData,
												rating: 4,
											})
										}
									></i>
									<i
										class={`bi cursor-pointer ${
											reviewData.rating >= 5
												? "bi-star-fill"
												: "bi-star"
										}`}
										onClick={() =>
											setReviewData({
												...reviewData,
												rating: 5,
											})
										}
									></i>
								</div>
							</div>
						</div>
						<div class="modal-footer">
							<button
								type="button"
								class="btn btn-secondary"
								data-bs-dismiss="modal"
								onClick={() =>
									setReviewData({
										rating: 0,
										review: "",
										title: "",
										id: "",
									})
								}
							>
								Close
							</button>
							<button
								type="button"
								class="btn btn-tertiary"
								data-bs-dismiss="modal"
								onClick={() => submitReview()}
							>
								Submit Review
							</button>
						</div>
					</div>
				</div>
			</div>
			{/* Modal to exhange a product */}
			{/* Modal to write a product review */}
			<div
				class="modal fade"
				id="exhangeModal"
				tabindex="-1"
				aria-labelledby="exchangeModalLabel"
				aria-hidden="true"
			>
				<div class="modal-dialog modal-dialog-centered">
					<div class="modal-content">
						<div class="modal-header">
							<h5 class="modal-title" id="exchangeModalLabel">
								Exchange Product
							</h5>
							<button
								type="button"
								class="btn-close"
								data-bs-dismiss="modal"
								aria-label="Close"
							></button>
						</div>
						<div class="modal-body d-flex flex-column justify-content-between gap-3">
							<div className="d-flex flex-column gap-1">
								{/* Reason for echange dropdown */}
								<label for="exchangeReason">Reason</label>
								<select
									className="form-select"
									aria-label="Default select example"
									id="exchangeReason"
									value={exchangeData.reason}
									onChange={(e) =>
										setExchangeData({
											...exchangeData,
											reason: e.target.value,
										})
									}
								>
									<option selected>Choose a reason</option>
									<option value="Damaged">Damaged</option>
									<option value="Defective">Defective</option>
									<option value="Wrong Product">
										Wrong Product
									</option>
									<option value="Not as described">
										Not as described
									</option>
									<option value="Other">Other</option>
								</select>
							</div>
							<div className="d-flex flex-column gap-1">
								{/* Description for reason */}
								<label for="exchangeDescription">
									Description
								</label>
								<textarea
									className="form-control"
									id="exchangeDescription"
									rows="3"
									placeholder="Write your description here..."
									value={exchangeData.description}
									onChange={(e) =>
										setExchangeData({
											...exchangeData,
											description: e.target.value,
										})
									}
								></textarea>
							</div>
						</div>
						<div class="modal-footer">
							<button
								type="button"
								class="btn btn-secondary"
								data-bs-dismiss="modal"
								onClick={() =>
									setExchangeData({
										reason: "",
										description: "",
										id: "",
									})
								}
							>
								Close
							</button>
							<button
								type="button"
								class="btn btn-tertiary"
								data-bs-dismiss="modal"
								onClick={() => sendExchangeRequest()}
							>
								Submit
							</button>
						</div>
					</div>
				</div>
			</div>
			{/* Modal to cancel the order */}
			<div
				class="modal fade"
				id="cancelModal"
				tabindex="-1"
				aria-labelledby="cancelModalLabel"
				aria-hidden="true"
			>
				<div class="modal-dialog modal-dialog-centered">
					<div class="modal-content">
						<div class="modal-header">
							<h5 class="modal-title" id="cancelModalLabel">
								Cancel Order
							</h5>
							<button
								type="button"
								class="btn-close"
								data-bs-dismiss="modal"
								aria-label="Close"
								onClick={() =>
									setCancelData({
										reason: "",
										id: "",
									})
								}
							></button>
						</div>
						<div class="modal-body d-flex flex-column justify-content-between gap-3">
							<div className="d-flex flex-column gap-1">
								{/* Reason for echange dropdown */}
								<label for="cancelReason">Reason</label>
								<select
									className="form-select"
									aria-label="Default select example"
									id="cancelReason"
									value={cancelData.reason}
									onChange={(e) =>
										setCancelData({
											...cancelData,
											reason: e.target.value,
										})
									}
								>
									<option selected>Choose a reason</option>
									<option value="Not Interested">
										Not Interested
									</option>
									<option value="Wrong Product">
										Wrong Product
									</option>
									<option value="Other">Other</option>
								</select>
							</div>
						</div>
						<div class="modal-footer">
							<button
								type="button"
								class="btn btn-secondary"
								data-bs-dismiss="modal"
								onClick={() =>
									setCancelData({
										reason: "",
										id: "",
									})
								}
							>
								Close
							</button>
							<button
								type="button"
								class="btn btn-tertiary"
								data-bs-dismiss="modal"
								onClick={() => sendCancelRequest()}
							>
								Submit
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Orders;
