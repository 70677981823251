import React, { useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../static/img/logo-wbg.png";
import Cart from "./Cart";
import Wishlist from "./Wishlist";
import useAuth from "../config/hooks/useAuth";
import { useEffect } from "react";
import useToken from "../config/hooks/useToken";
import "../assets/customHeader.css";

const Navbar = () => {
	const { state } = useAuth();
	const { userData } = useToken();
	const [dropdown, setDropdown] = useState(false);

	useEffect(() => {
		{dropdown? document.querySelector('#root').classList.add('opened-nav'):document.querySelector('#root').classList.remove('opened-nav')}
	}, [dropdown]);

	return (
		<>
			<section className="announcement-bar">
				<p className="announcement-text uppercase">
					Get 25% Off on First Order
				</p>
			</section>
			<nav className="navbar sticky-head flex-wrap navbar-expand-lg bg-primary fw-bold">
				<div className="container-fluid d-flex justify-content-between align-items-center px-md-5 px-3 py-2">
					<Link to="/">
						<img
							src={Logo}
							className=""
							width={200}
							height={50}
							alt="logo"
						/>
					</Link>
					<div
						className="d-flex gap-4 d-md-none position-absolute end-0 me-3"
						onClick={() => setDropdown(!dropdown)}
					>
						{dropdown ? (
							<i class="bi bi-x fs-1"></i>
						) : (
							<i class="bi bi-list fs-1"></i>
						)}
					</div>
					<div className="d-flex gap-4 d-none d-md-flex position-absolute end-0 me-5">
						{!state ? (
							<Link
								to={"/login"}
								className="fs-6 text-brown text-decoration-none cursor-pointer"
							>
								Login
							</Link>
						) : (
							<>
								<Link
									to={"/profile"}
									className="fs-5 text-brown text-decoration-none cursor-pointer"
								>
									<i className="bi bi-person-fill"></i>{" "}
								</Link>
							</>
						)}
						<span
							className="fs-6 text-brown text-decoration-none cursor-pointer"
							data-bs-toggle="offcanvas"
							href="#wishlistOffCanvas"
							role="button"
							aria-controls="wishlistOffCanvas"
						>
							<i className="bi bi-heart-fill"></i> (
							{localStorage.getItem("wishlist")
								? JSON.parse(localStorage.getItem("wishlist"))
										.length
								: 0}
							)
						</span>
						<span
							className="fs-6 text-brown text-decoration-none cursor-pointer"
							data-bs-toggle="offcanvas"
							href="#cartOffCanvas"
							role="button"
							aria-controls="cartOffCanvas"
						>
							<i className="bi bi-cart-fill"></i> (
							{localStorage.getItem("cart")
								? JSON.parse(localStorage.getItem("cart"))
										.length
								: 0}
							)
						</span>
					</div>
				</div>
				{dropdown && (
					<div className="d-flex gap-4 d-md-none flex-column justify-content-center align-items-center w-100">
						{!state ? (
							<Link
								to={"/login"}
								className="fs-6 text-brown text-decoration-none cursor-pointer"
							>
								Login
							</Link>
						) : (
							<>
								<Link
									to={"/profile"}
									className="fs-6 text-brown text-decoration-none cursor-pointer"
								>
									<i className="bi bi-person-fill"></i>
									{userData()?.name}
								</Link>
							</>
						)}
						<span
							className="fs-6 text-brown text-decoration-none cursor-pointer"
							data-bs-toggle="offcanvas"
							href="#wishlistOffCanvas"
							role="button"
							aria-controls="wishlistOffCanvas"
						>
							<i className="bi bi-heart-fill"></i> Wishlist (
							{localStorage.getItem("wishlist")
								? JSON.parse(localStorage.getItem("wishlist"))
										.length
								: 0}
							)
						</span>
						<span
							className="fs-6 text-brown text-decoration-none cursor-pointer"
							data-bs-toggle="offcanvas"
							href="#cartOffCanvas"
							role="button"
							aria-controls="cartOffCanvas"
						>
							<i className="bi bi-cart-fill"></i> Cart (
							{localStorage.getItem("cart")
								? JSON.parse(localStorage.getItem("cart"))
										.length
								: 0}
							)
						</span>
					</div>
				)}
			</nav>
			<Wishlist />
			<Cart />
		</>
	);
};

export default Navbar;
