import data from "../assets/Usp";
import '../assets/usp.css'

export function Usp({ image, description }) {
	return (
		<div className="usp-item d-flex flex-column gap-2 align-items-center p-3 border">
			<img className="usp-frame top" src={require('../media/usp/frameTry.webp')} />
			{image && (
				<img
					className="usp-image"
					src={require(`../media/usp/${image}`)}
				/>
			)}
			{description && <p className="usp-description">{description}</p>}
			<img className="usp-frame bottom" src={require('../media/usp/frameTry.webp')} />
		</div>
	);
}

export default function UspSection(props) {
	const { heading, usps } = data;
	console.log(usps);
	return (
		<section className="section usp d-flex flex-column m-2 my-4 p-4">
			{heading && <div className="d-flex justify-content-center align-items-center"><h3 className="usp-heading text-center section-heading style-heading">{heading}</h3></div>}

			{usps && (
				<div className="usp-item-container grid grid-4 grid-mob-2 gap-5">
					{usps.map((item) => (
						<Usp
							image={item.image}
							description={item.description}
						/>
					))}
				</div>
			)}
		</section>
	);
}
