export const initialState = (localStorage.getItem("token") && localStorage.getItem("user")) ? true : false;
export const initialWishlistState = localStorage.getItem("wishlist") ? JSON.parse(localStorage.getItem("wishlist")) : [];
export const initialCartState = localStorage.getItem("cart") ? JSON.parse(localStorage.getItem("cart")) : [];

export const reducer = (state, action) => {
    switch (action.type) {
        case "LOGIN":
            return true;
        case "LOGOUT":
            return false;
        case "USER":
            return action.payload;
        default:
            return state;
    }
}

export const wishlistReducer = (state, action) => {
    switch (action.type) {
        case "ADD_TO_WISHLIST":
            localStorage.setItem("wishlist", JSON.stringify([...state, action.payload]));
            return [...state, action.payload];
        case "REMOVE_FROM_WISHLIST":
            let updatedWishlist = state.filter((item) => item.id !== action.payload?.id);
            localStorage.setItem("wishlist", JSON.stringify(updatedWishlist));
            return updatedWishlist;
        default:
            return state;
    }
}

export const cartReducer = (state, action) => {
    switch (action.type) {
        case "ADD_TO_CART":
            localStorage.setItem("cart", JSON.stringify([...state, action.payload]));
            return [...state, action.payload];
        case "REMOVE_FROM_CART":
            let updatedCart = state.filter((item) => item.id !== action.payload?.id);
            localStorage.setItem("cart", JSON.stringify(updatedCart));
            return updatedCart;
        case "UPDATE_CART":
            let updatedCart2 = state.map((item) => {
                if (item.id === action.payload?.id) {
                    return action.payload;
                }
                return item;
            });
            localStorage.setItem("cart", JSON.stringify(updatedCart2));
            return updatedCart2;
        case "CLEAR_CART":
            localStorage.setItem("cart", JSON.stringify([]));
            return [];
        default:
            return state;
    }
}
