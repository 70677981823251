import React, { useState } from "react";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import AccountSettings from "../components/AccountSettings";
import AddressSettings from "../components/AddressSettings";
import CategoryNavbar from "../components/CategoryNavbar";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import Orders from "../components/Orders";
import useAuth from "../config/hooks/useAuth";
import useToken from "../config/hooks/useToken";

const Profile = () => {
	const { dispatch, cartDispatch } = useAuth();
	const { userData } = useToken();
	const { name, email } = userData();

	const [selectedTab, setSelectedTab] = useState("account");
	const [searchParams] = useSearchParams();

	const __renderTab = () => {
		if (selectedTab === "account") return <AccountSettings />;
		else if (selectedTab === "address") return <AddressSettings />;
		else if (selectedTab === "orders") return <Orders />;
		else return <AccountSettings />;
	};

	const __logout = () => {
		localStorage.removeItem("token");
		localStorage.removeItem("user");
		dispatch({ type: "LOGOUT", payload: false });
		window.location.href = "/login";
	};

	useEffect(() => {
		if (searchParams.get("tab")) {
			setSelectedTab(searchParams.get("tab"));
			cartDispatch({ type: "CLEAR_CART" });
			if (searchParams.get("payment") === "success") {
				toast.success("Payment Successful");
			} else if (searchParams.get("payment") === "failed") {
				toast.error("Payment Failed");
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<Navbar />
			<CategoryNavbar />
			<div className="container py-4">
				<div className="row">
					<div className="col-lg-3">
						<div className="row position-sticky top-0">
							<div className="col-12">
								<div className="border shadow-sm rounded m-2">
									<div className="p-3">
										<div className="d-flex justify-content-between flex-column">
											<h5 className="mb-0">{name}</h5>
											<p className="mb-0">{email}</p>
										</div>
										<div
											className="btn btn-sm btn-tertiary fs-8 mt-2"
											onClick={() => __logout()}
										>
											Logout
										</div>
									</div>
								</div>
							</div>
							<div className="col-12">
								<div className="border shadow-sm rounded m-2">
									<div className="p-3">
										<div
											className="d-flex gap-3 align-items-center cursor-pointer"
											onClick={() =>
												setSelectedTab("account")
											}
										>
											<i className="fas fa-user"></i>
											<p className="mb-0 fs-6 fw-bold">
												Account Settings
											</p>
										</div>
										<div
											className="d-flex gap-3 align-items-center cursor-pointer mt-2"
											onClick={() =>
												setSelectedTab("address")
											}
										>
											<i className="fas fa-map-marker-alt"></i>
											<p className="mb-0 fs-6 fw-bold">
												Manage Addresses
											</p>
										</div>
										<div
											className="d-flex gap-3 align-items-center cursor-pointer mt-2"
											onClick={() =>
												setSelectedTab("orders")
											}
										>
											<i class="fas fa-shopping-bag"></i>
											<p className="mb-0 fs-6 fw-bold">
												Orders
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-9">
						<div className="border shadow-sm rounded m-2">
							{__renderTab()}
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</>
	);
};

export default Profile;
