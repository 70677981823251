const useToken = () => {
    const getToken = () => {
        const token = localStorage.getItem('token');
        return token ? token : null;
    };

    const saveToken = (token) => {
        localStorage.setItem('token', token);
    };

    const userData = () => {
        const data = localStorage.getItem('user');
        return data ? JSON.parse(data) : null;
    };

    const isLogin = () => {
        const token = getToken();
        const user = userData();
        console.log(token, user);
        return (token && user) ? true : false;
    }

    return {
        getToken,
        saveToken,
        userData,
        isLogin
    }
}

export default useToken;