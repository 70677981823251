import axios from "axios";
import {
	RecaptchaVerifier,
	signInWithPhoneNumber,
	updateProfile,
} from "firebase/auth";
import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { GLOBAL_URL } from "../config/global/constant";
import { auth } from "../config/global/Firebase";
import useAuth from "../config/hooks/useAuth";

const GuestUserLoginModal = ({login}) => {
	const [guestName, setGuestName] = useState("");
	const [guestEmail, setGuestEmail] = useState("");
	const [guestPhone, setGuestPhone] = useState("");
	const [otp, setOtp] = useState("");
	const [otpSent, setOtpSent] = useState(false);
	const [loading, setLoading] = useState(false);
	const [confirmationResult, setConfirmationResult] = useState(null);

    const btnRef = useRef(null);

	const { dispatch } = useAuth();

	const navigate = useNavigate();

	const handleSubmit = async () => {
		setLoading(true);
		if (!login) {
			if (guestName === "" || guestEmail === "" || guestPhone === "") {
				toast.error("All fields are required!");
				setLoading(false);
				return;
			}
		} else {
			if (guestPhone === "") {
				toast.error("Phone number is required!");
				setLoading(false);
				return;
			}
		}
		if (!otpSent) {
			await sendOtp();
		} else {
			await verifyOtp();
		}
		setLoading(false);
	};

	const sendOtp = async () => {
		console.log("sendOtp");
		var appVerifier = new RecaptchaVerifier(
			"recaptcha-container",
			{
				size: "invisible",
				callback: (response) => {
					// reCAPTCHA solved, allow signInWithPhoneNumber.
					// onSignInSubmit();
					console.log(response);
				},
			},
			auth
		);
		console.log(appVerifier);
		const phone = "+91" + guestPhone;
		await signInWithPhoneNumber(auth, phone, appVerifier)
			.then(async (confirmationResult) => {
				// SMS sent. Prompt user to type the code from the message, then sign the
				// user in with confirmationResult.confirm(code).
				console.log(confirmationResult);
				setConfirmationResult(confirmationResult);
				if (!login) {
					await axios
						.post(`${GLOBAL_URL}/auth/register`, {
							name: guestName,
							email: guestEmail,
							password: "ThisIsGuestPassword",
							phone_number: guestPhone,
						})
						.then((res) => {
							console.log(res);
							setOtpSent(true);
						})
						.catch((err) => {
							console.log(err);
							toast.error("Email already exists!");
							// rerender the captcha
							setTimeout(() => {
								window.location.reload();
							}, 1000);
							return;
						});
				} else {
					setOtpSent(true);
				}
			})
			.catch((error) => {
				console.log(error);
				toast.error("Unable to send OTP!");
                setTimeout(() => {
                    window.location.reload();
                }, 1000);
			});
	};

	const verifyOtp = async () => {
		console.log("verifyOtp");
        setLoading(true);
		confirmationResult
			.confirm(otp)
			.then(async (result) => {
				// User signed in successfully.
				const user = result.user;
				console.log(user);
				axios
					.post(`${GLOBAL_URL}/auth/login`, {
						email: guestEmail,
						password: "ThisIsGuestPassword",
						otp: true
					})
					.then((res) => {
						localStorage.setItem("token", res.data.data.token);
						localStorage.setItem(
							"user",
							JSON.stringify(res.data.data.user)
						);
						dispatch({
							type: "USER",
							payload: res.data.data.user,
						});
					})
					.catch((err) => {
						toast.error("Please use another email!");
						console.log(err);
						return;
					});
				updateProfile(auth.currentUser, {
					displayName: guestName,
					email: guestEmail,
				})
					.then(() => {
                        localStorage.setItem("guest", true);
                        btnRef.current.click();
						navigate("/checkout");
						console.log("User profile updated!");
					})
					.catch((error) => {
						console.log(error);
					});
			})
			.catch((error) => {
				// User couldn't sign in (bad verification code?)
				// ...
				console.log(error);
				toast.error("Invalid OTP!");
			});
        setLoading(false);
	};

	return (
		<div
			class="modal fade"
			id="guestModal"
			tabindex="-1"
			aria-labelledby="guestModalLabel"
			aria-hidden="true"
			data-bs-backdrop="static"
		>
			<div
				class="modal-dialog modal-dialog-centered"
				data-bs-config='{"backdrop":true}'
			>
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title" id="guestModalLabel">
							{login ? "Login via OTP" : "Guest Login"}
						</h5>
						<button
							type="button"
							class="btn-close"
							data-bs-dismiss="modal"
							aria-label="Close"
						></button>
					</div>
					<div class="modal-body d-flex flex-column justify-content-between gap-3">
						{!otpSent ? (
							<>
							{!login && (
								<div className="d-flex flex-column gap-1">
									<label for="guestName" class="form-label">
										Name
									</label>
									<input
										type="text"
										class="form-control"
										id="guestName"
										placeholder="Enter your name"
										onChange={(e) =>
											setGuestName(e.target.value)
										}
										value={guestName}
									/>
								</div>
							)}
								<div className="d-flex flex-column gap-1">
									<label for="guestEmail" class="form-label">
										Email
									</label>
									<input
										type="email"
										class="form-control"
										id="guestEmail"
										placeholder="Enter your email"
										onChange={(e) =>
											setGuestEmail(e.target.value)
										}
										value={guestEmail}
									/>
								</div>
								<div className="d-flex flex-column gap-1">
									<label for="guestPhone" class="form-label">
										Phone
									</label>
									<input
										type="text"
										class="form-control"
										id="guestPhone"
										placeholder="Enter your phone"
										onChange={(e) =>
											setGuestPhone(e.target.value)
										}
										value={guestPhone}
										maxLength="10"
										minLength="10"
									/>
								</div>
							</>
						) : (
							<div className="d-flex flex-column gap-1">
								<label for="otp" class="form-label">
									OTP
								</label>
								<input
									type="text"
									class="form-control"
									id="otp"
									placeholder="Enter OTP"
									onChange={(e) => setOtp(e.target.value)}
									value={otp}
									maxLength="6"
									minLength="6"
								/>
							</div>
						)}
					</div>
					<div class="modal-footer">
						<button
							type="button"
							class="btn btn-secondary"
							data-bs-dismiss="modal"
							onClick={() => setGuestName("")}
                            ref={btnRef}
						>
							Close
						</button>
						<button
							type="button"
							class="btn btn-tertiary"
							id="recaptcha-container"
							onClick={() => handleSubmit()}
						>
							{loading ? (
								<div
									class="spinner-border text-light spinner-border-sm"
									role="status"
								>
									<span class="visually-hidden">
										Loading...
									</span>
								</div>
							) : otpSent ? (
								"Login"
							) : (
								"Send OTP"
							)}
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default GuestUserLoginModal;
