import React from "react";
import { Route, Routes } from "react-router-dom";
import AboutUs from "../../pages/AboutUs";
import Checkout from "../../pages/Checkout";
import Contact from "../../pages/Contact";
import ExchangePolicy from "../../pages/ExchangePolicy";
import Faq from "../../pages/Faq";
import Home from "../../pages/Home";
import Login from "../../pages/Login";
import PrivacyPolicy from "../../pages/PrivacyPolicy";
import ProductDetail from "../../pages/ProductDetail";
import Profile from "../../pages/Profile";
import Register from "../../pages/Register";
import ReturnPolicy from "../../pages/ReturnPolicy";
import SearchPage from "../../pages/SearchPage";
import ShippingPolicy from "../../pages/ShippingPolicy";
import TermsAndConditions from "../../pages/TermsAndConditions";
import { AuthProvider } from "../context/AuthContext";
import PrivateRoute from "./PrivateRoute";

const Router = () => {
	return (
		<AuthProvider>
			<Routes>
				<Route path="/" element={<Home />} />
				<Route path="/:cat/:id/:name" element={<ProductDetail />} />
				<Route path="/search/:type/:id/:name" element={<SearchPage />} />
				<Route path="/login" element={<Login />} />
				<Route path="/register" element={<Register />} />
				<Route path="/checkout" element={<Checkout />} />
				<Route path="/profile" element={<PrivateRoute />}>
					<Route path="/profile" element={<Profile />} />
				</Route>
				<Route path="/about-us" element={<AboutUs />} />
				<Route path="/contact-us" element={<Contact />} />
				<Route path="/faqs" element={<Faq />} />
				<Route path="/terms-and-conditions" element={<TermsAndConditions />} />
				<Route path="/privacy-policy" element={<PrivacyPolicy />} />
				<Route path="/shipping-policy" element={<ShippingPolicy />} />
				<Route path="/return-policy" element={<ReturnPolicy />} />
				<Route path="/exchange-policy" element={<ExchangePolicy />} />
				<Route path="/*" element={<h1>Not found</h1>} />
			</Routes>
		</AuthProvider>
	);
};

export default Router;
