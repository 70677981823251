const testimonials  = [
    {
        name: "shubham",
        description: "This Product is simply outstanding. It delivers on all fronts—performance, design, and functionality. The build quality is excellent, and the attention to detail is evident. The product has exceeded my expectations, and I highly recommend it.",
        rating: 5,
        image: 'https://cdn.shopify.com/s/files/1/0601/0414/7197/files/Img_1.png?v=1686635775'
    },
    {
        name: "Ruchika",
        description: "I recently purchased Product A, and I must say it exceeded my expectations. The build quality is top-notch, and it performs flawlessly. The features provided are impressive, and the user interface is intuitive. The only minor drawback is the price, but considering the overall quality, it's worth the investment.",
        rating: 4,
        image: 'https://cdn.shopify.com/s/files/1/0601/0414/7197/files/Img_2.png?v=1686635775'
    },
    {
        name: "shivani",
        description: "I've been using This Product for a while now, and I'm impressed with its performance. The features it offers are reliable and efficient. The build quality is sturdy, and it feels great to use. Overall, This Product is a solid option for its price range.",
        rating: 4.5,
        image: 'https://cdn.shopify.com/s/files/1/0601/0414/7197/files/Img_3.png?v=1686635775'
    },
    {
        name: "Aastha",
        description: "This Product exceeded my expectations. It's a versatile product with excellent features. The performance is top-notch, and the user interface is user-friendly. The value for money is exceptional, and I couldn't be happier with my purchase.",
        rating: 5,
        image: 'https://cdn.shopify.com/s/files/1/0601/0414/7197/files/Img_5.png?v=1686635775'
    },
    {
        name: "Rohan",
        description: "I recently bought This Product, and it has been a great investment. The quality and performance are impressive. The product offers a wide range of features that are easy to use. It has enhanced my productivity and made my tasks much more manageable.",
        rating: 4,
        image: 'https://cdn.shopify.com/s/files/1/0601/0414/7197/files/Img_4.png?v=1686635775'
    }
]

export default testimonials;