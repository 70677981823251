import React from "react";
import { ReactComponent as Loader } from "../static/img/spinner.svg";

const Spinner = ({bgClass}) => {
	return (
		<div className={`h-full w-full d-flex justify-content-center align-items-center ${bgClass ? bgClass : "bg-primary"}`}>
			<Loader className={`${bgClass && bgClass}`} />
		</div>
	);
};

export default Spinner;
