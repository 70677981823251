import axios from "axios";
import React, { useEffect, useState } from "react";
import CategoryNavbar from "../components/CategoryNavbar";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import Spinner from "../components/Spinner";
import { GLOBAL_URL } from "../config/global/constant";

const Faq = () => {
	const [faqs, setFaqs] = useState("");
    const [loading, setLoading] = useState(true);

	const getExchangePolicy = async () => {
		axios
			.get(`${GLOBAL_URL}/general/faq`, {
				headers: {
					"Content-Type": "application/json",
				},
			})
			.then((res) => {
				console.log(res.data.data);
				setFaqs(res.data.data);
			})
			.catch((err) => {
				console.log(err);
			})
            .finally(() => {
                setLoading(false);
            });
	};

	useEffect(() => {
		getExchangePolicy();
	}, []);

    if (loading) {
        return (
            <Spinner />
        )
    }

	return (
		<>
			<Navbar />
			<CategoryNavbar />
			<div className="container py-4">
                <h1 className="pb-4">FAQs</h1>
                <div className="accordion" id="faq">
                    {
                        faqs.map((faq, index) => (
                            <div className="accordion-item" key={index}>
                                <h2 className="accordion-header" id={`ques_${index}`}>
                                <button className="accordion-button fw-bold" type="button" data-bs-toggle="collapse" data-bs-target={`#ans_${index}`} aria-expanded="false" aria-controls={`ans_${index}`}>
                                    {faq.question}
                                </button>
                                </h2>
                                <div id={`ans_${index}`} className="accordion-collapse collapse" aria-labelledby={`ques_${index}`} data-bs-parent="#faq">
                                <div className="accordion-body">
                                    {faq.answer}
                                </div>
                                </div>
                            </div>
                            // <div className="accordion-item" key={index}>
                            //     <h2 className="accordion-header" id={`ques_${index}`}>
                            //     <button className="accordion-button fw-bold" type="button" data-bs-toggle="collapse" data-bs-target={`#ans_${index}`} aria-expanded="true" aria-controls={`ans_${index}`}>
                            //         {faq.question}
                            //     </button>
                            //     </h2>
                            //     <div id={`ans_${index}`} className="accordion-collapse collapse show" aria-labelledby={`ques_${index}`} data-bs-parent="#faq">
                            //         <div className="accordion-body">
                            //             {faq.answer}    
                            //         </div>
                            //     </div>
                            // </div>
                        ))
                    }
                </div>
				<div
					dangerouslySetInnerHTML={{ __html: faqs.content }}
				></div>
			</div>
			<Footer />
		</>
	);
};

export default Faq;
