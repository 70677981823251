import axios from "axios";
import React, {
	createContext,
	useEffect,
	useMemo,
	useReducer,
	useState,
} from "react";
import Spinner from "../../components/Spinner";
import { GLOBAL_URL } from "../global/constant";

import {
	cartReducer,
	initialCartState,
	initialState,
	initialWishlistState,
	reducer,
	wishlistReducer,
} from "../hooks/useReducer";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
	const [loading, setLoading] = useState(true);
	const [categoryData, setCategoryData] = useState([]);
	const [carouselData, setCarouselData] = useState([]);
	const [productData, setProductData] = useState([]);

	const [state, dispatch] = useReducer(reducer, initialState);
	const [wishlist, wishlistDispatch] = useReducer(
		wishlistReducer,
		initialWishlistState
	);
	const [cart, cartDispatch] = useReducer(cartReducer, initialCartState);

	const getProductData = async () => {
		setLoading(true);
		await axios
			.get(`${GLOBAL_URL}/product/sub-category/product/list`, {
				headers: {
					"Content-Type": "application/json",
				},
			})
			.then((response) => {
				setProductData(response.data.data);
			})
			.catch((error) => {
				console.log(error);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const getCarouselData = async () => {
		setLoading(true);
		await axios
			.get(`${GLOBAL_URL}/global/floating`, {
				headers: {
					"Content-Type": "application/json",
				},
			})
			.then((response) => {
				setCarouselData(response.data.data);
			})
			.catch((error) => {
				console.log(error);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const getCategoryData = async () => {
		setLoading(true);
		await axios
			.get(`${GLOBAL_URL}/product/category/list`, {
				headers: {
					"Content-Type": "application/json",
				},
			})
			.then((response) => {
				setCategoryData(response.data.data);
			})
			.catch((error) => {
				console.log(error);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	useEffect(() => {
		getCategoryData();
		getCarouselData();
		getProductData();
	}, []);

	const memoedValue = useMemo(
		() => ({
			state,
			dispatch,
			categoryData,
			carouselData,
			productData,
			wishlist,
			wishlistDispatch,
			cart,
			cartDispatch,
		}),
		[
			state,
			dispatch,
			categoryData,
			carouselData,
			productData,
			wishlist,
			wishlistDispatch,
			cart,
			cartDispatch,
		]
	);
	return (
		<AuthContext.Provider value={memoedValue}>
			{loading ? <Spinner /> : !loading && children}
		</AuthContext.Provider>
	);
};

export default AuthContext;
