import React from "react";

const NewLaunchProductCard = ({ name, description, images, setModalData }) => {
	return (
		<div
			className="d-flex flex-column cursor-pointer"
			data-bs-toggle="modal"
			data-bs-target="#comingSoonModal"
			onClick={() => setModalData({ name, description, images })}
		>
			<div className="card newLaunch-card">
				<div className="row h-100">
					<div className="col-6 h-100 p-0 img-container">
						<img
							className="img-fluid product-img"
							src={images[0]}
							alt="image_1"
						/>
					</div>
					<div className="col-6 h-100 p-0">
						<div className="d-flex flex-column h-100">
							<div className="flex-1 img-container">
								<img
									className="img-fluid product-img"
									src={images[1]}
									alt="image_1"
								/>
							</div>
							<div className="flex-1 img-container">
								<img
									className="img-fluid product-img"
									src={images[2]}
									alt="image_1"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default NewLaunchProductCard;
