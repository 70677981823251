import React from "react";
import { Link } from "react-router-dom";
import { slugify } from "../config/global/utils";
import useAuth from "../config/hooks/useAuth";
import { ReactComponent as DeleteIcon } from "../static/img/delete.svg";

const Wishlist = () => {
	const {wishlist, wishlistDispatch} = useAuth();

	const removeItem = (e, item) => {
		e.stopPropagation();
		wishlistDispatch({ type: "REMOVE_FROM_WISHLIST", payload: item });
		e.preventDefault();
	}

	return (
		<div
			className="offcanvas offcanvas-end"
			tabIndex="-1"
			id="wishlistOffCanvas"
			aria-labelledby="wishlistOffCanvasLabel"
		>
			<div className="offcanvas-header">
				<h5 className="offcanvas-title" id="wishlistOffCanvasLabel">
					Wishlist
				</h5>
				<button
					type="button"
					className="btn-close text-reset"
					data-bs-dismiss="offcanvas"
					aria-label="Close"
				></button>
			</div>
			<div className="offcanvas-body d-flex flex-column justify-content-between p-0">
				<div className="container-fluid overflow-y-scroll">
					{wishlist.length > 0 ? (
						wishlist.map((item, index) => (
							<Link
								to={`/product/${item?.id}/${slugify(item?.name)}`}
								key={index}
							>
								<div
									className="row py-1 border-bottom"
									key={index}
								>
									<div className="col-3 d-flex justify-content-center align-items-center">
										<img
											src={item.image}
											alt="img"
											className="img-fluid rounded"
										/>
									</div>
									<div className="col-6 d-flex flex-column">
										<div>{item.name}</div>
										<div className="d-flex flex-column justify-content-between text-smaller">
											<p>₹{item.price}</p>
										</div>
									</div>
									<div className="col-3 d-flex flex-column justify-content-between">
										<div
											className="cursor-pointer"
											onClick={(e) => removeItem(e, item)}
										>
											<DeleteIcon
												style={{
													height: "20px",
													width: "20px",
													marginBottom: "5px",
												}}
											/>
										</div>
									</div>
								</div>
							</Link>
						))
					) : (
						<div className="text-center py-5">
							<h5 className="text-brown">No items in wishlist</h5>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default Wishlist;
