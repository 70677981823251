import React from "react";
import useAuth from "../config/hooks/useAuth";

const Carousel = () => {
	const { carouselData } = useAuth();

	return (
		<div
			id="carouselExampleIndicators"
			className="carousel slide"
			data-bs-ride="true"
		>
			<div className="carousel-indicators">
				{carouselData.map((item, index) => (
					<button
						key={index}
						type="button"
						data-bs-target="#carouselExampleIndicators"
						data-bs-slide-to={index}
						className={index === 0 ? "active" : ""}
						aria-current="true"
						aria-label={`Slide ${index + 1}`}
					></button>
				))}
			</div>
			<div className="carousel-inner" data-bs-ride="carousel">
				{carouselData.map((item, index) => (
					<div
						className={`carousel-item ${
							index === 0 ? "active" : ""
						}`}
						key={index}
					>
						<img
							src={`${item.image}`}
							className="d-block w-100"
							alt="..."
						/>
						<img
							src={`${item.image}`}
							className="d-block w-100"
							alt="..."
						/>
						<div className="overlay-img"></div>
					</div>
				))}
			</div>
			<button
				className="carousel-control-prev"
				type="button"
				data-bs-target="#carouselExampleIndicators"
				data-bs-slide="prev"
			>
				<span
					className="carousel-control-prev-icon"
					aria-hidden="true"
				></span>
				<span className="visually-hidden">Previous</span>
			</button>
			<button
				className="carousel-control-next"
				type="button"
				data-bs-target="#carouselExampleIndicators"
				data-bs-slide="next"
			>
				<span
					className="carousel-control-next-icon"
					aria-hidden="true"
				></span>
				<span className="visually-hidden">Next</span>
			</button>
		</div>
	);
};

export default Carousel;
