import React from "react";

const ZoomedProductImages = ({ images, setShowZoomedImages }) => {
	return (
		<>
			<div
				className="modal-backdrop fade show"
				onClick={() => setShowZoomedImages(false)}
			></div>
			<div
				className="position-fixed cursor-pointer"
				style={{
					top: "20px",
					right: "30px",
					zIndex: 2001,
				}}
				onClick={() => setShowZoomedImages(false)}
			>
				<i className="fas fa-times fa-2x text-white"></i>
			</div>
			<div
				className="container-fluid position-fixed top-0 start-0 end-0 px-0"
				style={{ zIndex: 2000 }}
			>
				<div className="row">
					<div
						id="comingSoonItemModal"
						className="carousel slide"
						data-bs-ride="true"
						style={{
							width: "100%",
							height: "100vh",
						}}
					>
						<div className="carousel-indicators">
							{images?.map((item, index) => (
								<button
									key={index}
									type="button"
									data-bs-target="#comingSoonItemModal"
									data-bs-slide-to={index}
									className={index === 0 ? "active" : ""}
									aria-current="true"
									aria-label={`Slide ${index + 1}`}
								></button>
							))}
						</div>
						<div
							className="carousel-inner"
							style={{ height: "100vh" }}
						>
							{images?.map((item, index) => (
								<div
									className={`carousel-item ${
										index === 0 ? "active" : ""
									}`}
									key={index}
									style={{ height: "100vh" }}
								>
									<img
										src={`${item}`}
										className="d-block w-100"
										alt="..."
										style={{ height: "100%" }}
									/>
									<img
										src={`${item}`}
										className="d-block w-100"
										alt="..."
										style={{ height: "100%" }}
									/>
									<div
										className="overlay-img"
										style={{ height: "100%" }}
									></div>
								</div>
							))}
						</div>
						<button
							className="carousel-control-prev"
							type="button"
							data-bs-target="#comingSoonItemModal"
							data-bs-slide="prev"
						>
							<span
								className="carousel-control-prev-icon text-dark"
								aria-hidden="true"
							></span>
							<span className="visually-hidden">Previous</span>
						</button>
						<button
							className="carousel-control-next"
							type="button"
							data-bs-target="#comingSoonItemModal"
							data-bs-slide="next"
						>
							<span
								className="carousel-control-next-icon"
								aria-hidden="true"
							></span>
							<span className="visually-hidden">Next</span>
						</button>
					</div>
				</div>
			</div>
		</>
	);
};

export default ZoomedProductImages;
