export default {
    heading: 'Why Choose Us',
    usps: [
        {
            image: 'quality.svg',
            description: 'Quality Products'
        } ,
         {
             image: 'pricing.svg',
             description: 'Effective Pricing'
         },
         {
             image:  'vehicle.svg',
             description: 'Fast Delivery'
         },
         {
             image: 'contact.svg',
           description: 'Execcisve Support'          
         }
    ]
}