import axios from "axios";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { GLOBAL_URL } from "../config/global/constant";
import useToken from "../config/hooks/useToken";

const AccountSettings = () => {
	const { userData } = useToken();
	const { name, phone_number, gender } = userData();

	const [editUser, setEditUser] = useState(false);
    const [editedUser, setEditedUser] = useState({
        name: name,
        phone_number: phone_number,
        gender: gender
    });
    const [loading, setLoading] = useState(false);

    const __editUser = async () => {
        setLoading(true);
        await axios.put(`${GLOBAL_URL}/auth/update/user`, {
            name: editedUser.name,
            phone_number: editedUser.phone_number,
            gender: editedUser.gender
        }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Token ${localStorage.getItem("token")}`
            }
        })
        .then((res) => {
            toast.success("User updated successfully");
            console.log(res.data);
        })
        .catch((err) => {
            toast.error("Something went wrong");
            console.log(err);
        })
        .finally(() => {
            setLoading(false);
            setEditUser(false);
        })
    }

	return (
		<div className="row p-4">
			<div className="col-12">
				<div className="d-flex align-items-center gap-4">
					<p className="mb-0 fs-6 fw-bold">Personal Information</p>
                    {
                        editUser ? (
                            loading ? (
                                <div className="spinner-border text-brown" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            ) : (
                                <p
                                    className="mb-0 text-brown cursor-pointer"
                                    onClick={() => __editUser()}
                                >
                                    Save<i class="fas fa-save ps-1"></i>
                                </p>
                            )
                        ) : (
                            <p
                                className="mb-0 text-brown cursor-pointer"
                                onClick={() => setEditUser(true)}
                            >
                                Edit<i class="fas fa-user-edit ps-1"></i>
                            </p>
                        )
                    }
				</div>
				<div class="row my-3">
					<label for="name" class="col-sm-2 col-form-label">
						Name
					</label>
					<div class="col-sm-10">
						<input
							type="text"
							class="form-control"
							id="name"
							value={editedUser.name}
                            disabled={!editUser}
                            onChange={(e) => setEditedUser({...editedUser, name: e.target.value})}
						/>
					</div>
				</div>
				<div class="row mb-3">
					<label for="phone" class="col-sm-2 col-form-label">
						Phone Number
					</label>
					<div class="col-sm-10">
						<input
							type="text"
							maxLength={10}
							minLength={10}
							class="form-control"
							id="phone"
                            value={editedUser.phone_number}
                            disabled={!editUser}
                            onChange={(e) => setEditedUser({...editedUser, phone_number: e.target.value})}
						/>
					</div>
				</div>
				<fieldset class="row mb-3">
					<legend class="col-form-label col-sm-2 pt-0">Gender</legend>
					<div class="col-sm-10 d-flex gap-3">
						<div class="form-check">
							<input
								class="form-check-input"
								type="radio"
								name="gridRadios"
								id="male"
								value="Male"
								checked={editedUser.gender === "Male"}
                                disabled={!editUser}
                                onChange={(e) => setEditedUser({...editedUser, gender: e.target.value})}
							/>
							<label class="form-check-label" for="male">
								Male
							</label>
						</div>
						<div class="form-check">
							<input
								class="form-check-input"
								type="radio"
								name="gridRadios"
								id="female"
								value="Female"
                                checked={editedUser.gender === "Female"}
                                disabled={!editUser}
                                onChange={(e) => setEditedUser({...editedUser, gender: e.target.value})}
							/>
							<label class="form-check-label" for="female">
								Female
							</label>
						</div>
						<div class="form-check">
							<input
								class="form-check-input"
								type="radio"
								name="gridRadios"
								id="others"
								value="Others"
                                checked={editedUser.gender === "Others"}
                                disabled={!editUser}
                                onChange={(e) => setEditedUser({...editedUser, gender: e.target.value})}
							/>
							<label class="form-check-label" for="others">
								Others
							</label>
						</div>
					</div>
				</fieldset>
			</div>
		</div>
	);
};

export default AccountSettings;
