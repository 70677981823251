import axios from "axios";
import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";
import CategoryNavbar from "../components/CategoryNavbar";
import Footer from "../components/Footer";
import GuestUserLoginModal from "../components/GuestUserLoginModal";
import Navbar from "../components/Navbar";
import Spinner from "../components/Spinner";
import { GLOBAL_URL } from "../config/global/constant";
import useAuth from "../config/hooks/useAuth";
import useToken from "../config/hooks/useToken";
import { ReactComponent as Empty } from "../static/img/empty.svg";

const Checkout = () => {
	const [paymentMode, setPaymentMode] = useState("Online");
	const [couponCode, setCouponCode] = useState("");
	const [discount, setDiscount] = useState(0);
	const [subTotal, setSubTotal] = useState(0);
	const [grandTotal, setGrandTotal] = useState(0);
	const [addressList, setAddressList] = useState([]);
	const [selectedAddress, setSelectedAddress] = useState(null);
	const [newAddress, setNewAddress] = useState({
		type: "Home",
		name: "",
		phone: "",
		addressLine1: "",
		addressLine2: "",
		pincode: "",
		city: "",
		state: "",
		country: "India",
	});
	const [editAddress, setEditAddress] = useState(false);
	const [loading, setLoading] = useState(false);
	const [priceSaved, setPriceSaved] = useState(0);
	const [salesReference, setSalesReference] = useState("");

	const { cart, cartDispatch, state, dispatch } = useAuth();
	const { userData, isLogin } = useToken();
	const guestModalRef = useRef();

	const removeItem = (item) => {
		cartDispatch({ type: "REMOVE_FROM_CART", payload: item });
	};

	const updateCart = (item, action) => {
		if (action === "inc") {
			item.quantity += 1;
		} else if (action === "dec") {
			item.quantity -= 1;
		}
		if (item.quantity === 0) {
			removeItem(item);
		} else {
			cartDispatch({ type: "UPDATE_CART", payload: item });
		}
	};

	const checkCoupon = async () => {
		await axios
			.get(`${GLOBAL_URL}/product/coupon/apply?code=${couponCode}`, {
				headers: {
					"Content-Type": "application/json",
					Authorization: `Token ${localStorage.getItem("token")}`,
				},
			})
			.then((res) => {
				setDiscount(res.data.data.discount);
				toast.success("Coupon Applied");
			})
			.catch((err) => {
				toast.error("Invalid Coupon Code");
			});
	};

	const handleAddAddress = async () => {
		const url = editAddress
			? `${GLOBAL_URL}/auth/address/${selectedAddress.id}`
			: `${GLOBAL_URL}/auth/address`;
		if (editAddress) {
			await axios
				.put(
					url,
					{
						address_type: newAddress.type,
						name: newAddress.name,
						phone_number: newAddress.phone,
						address_line_1: newAddress.addressLine1,
						address_line_2: newAddress.addressLine2,
						pin_code: newAddress.pincode,
						city: newAddress.city,
						state: newAddress.state,
						country: newAddress.country,
					},
					{
						headers: {
							"Content-Type": "application/json",
							Authorization: `Token ${localStorage.getItem(
								"token"
							)}`,
						},
					}
				)
				.then((res) => {
					const user = JSON.parse(localStorage.getItem("user"));
					// check if address is already present
					const index = user.address.findIndex(
						(address) => address.id === res.data.data.id
					);
					console.log("index", index);
					if (index === -1) {
						user.address.push(res.data.data);
					} else {
						user.address[index] = res.data.data;
					}
					localStorage.setItem("user", JSON.stringify(user));
					dispatch({ type: "USER", payload: user });
					setNewAddress({
						type: "Home",
						name: "",
						phone: "",
						addressLine1: "",
						addressLine2: "",
						pincode: "",
						city: "",
						state: "",
						country: "India",
					});
					toast.success("Address Updated");
				})
				.catch((err) => {
					console.log(err);
					toast.error("Invalid Address");
				});
		} else {
			await axios
				.post(
					url,
					{
						address_type: newAddress.type,
						name: newAddress.name,
						phone_number: newAddress.phone,
						address_line_1: newAddress.addressLine1,
						address_line_2: newAddress.addressLine2,
						pin_code: newAddress.pincode,
						city: newAddress.city,
						state: newAddress.state,
						country: newAddress.country,
					},
					{
						headers: {
							"Content-Type": "application/json",
							Authorization: `Token ${localStorage.getItem(
								"token"
							)}`,
						},
					}
				)
				.then((res) => {
					const user = JSON.parse(localStorage.getItem("user"));
					user.address.push(res.data.data);
					localStorage.setItem("user", JSON.stringify(user));
					dispatch({ type: "USER", payload: user });
					setNewAddress({
						type: "Home",
						name: "",
						phone: "",
						addressLine1: "",
						addressLine2: "",
						pincode: "",
						city: "",
						state: "",
						country: "India",
					});
					toast.success("Address Added");
				})
				.catch((err) => {
					console.log(err);
					toast.error("Invalid Address");
				});
		}
	};

	const handleOrder = async () => {
		if (!selectedAddress) {
			toast.error("Please select an address");
			return;
		}
		if (!paymentMode) {
			toast.error("Please select a payment mode");
			return;
		}
		const products = cart.map((item) => ({
			id: item.id,
			amount: item.price,
			quantity: item.quantity,
			color: item.colorId,
			size: item.size || "",
		}));

		const data = {
			total_amount: parseFloat(grandTotal),
			coupon_code: couponCode,
			payment_mode: paymentMode,
			address: selectedAddress.id,
			products,
			sales_referance: salesReference
		};
		await axios
			.post(`${GLOBAL_URL}/payment/billing`, data, {
				headers: {
					"Content-Type": "application/json",
					Authorization: `Token ${localStorage.getItem("token")}`,
				},
			})
			.then((res) => {
				if (res.data.data.payment_mode === "Online") {
					const options = {
						key: "rzp_live_bsGMwcnKKqdWwX", // Enter the Key ID generated from the Dashboard
						amount: `${res.data.data.amount}`, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
						currency: "INR",
						name: "Nawabii",
						theme: {
							color: "#462d2d",
						},
						image: "https://nawabii.in/images/nawabilogo.jpeg",
						description: "Payment for Order",
						order_id: `${res.data.data.order_id}`, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
						callback_url: `${GLOBAL_URL}/payment/success-payment`,
						prefill: {
							name: `${userData().name}`,
							email: `${userData().email}`,
							contact: `+91${userData().phone_number}`,
						},
					};
					const rzp1 = new window.Razorpay(options);
					if (res.data.data.id !== null) {
						rzp1.open();
					}
				} else {
					toast.success("Order Placed");
					window.location.href = "/profile?tab=orders";
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	useEffect(() => {
		let total = 0;
		let saved = 0;
		cart.forEach((item) => {
			total += item.price * item.quantity;
			saved += (item.priceSaved * item.quantity);
		});
		setPriceSaved(saved);
		setSubTotal(total);
		// if (paymentMode === "Cash On Delivery") {
		// 	total += 80;
		// }
		setGrandTotal((total - (total * discount) / 100).toFixed(2));
	}, [cart, discount]);

	useEffect(() => {
		setAddressList(JSON.parse(localStorage.getItem("user"))?.address);
		if (!selectedAddress) {
			setSelectedAddress(
				JSON.parse(localStorage.getItem("user"))?.address[0]
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state]);

	useEffect(() => {
		setLoading(true);
		if (!isLogin()) {
			guestModalRef.current.click();
		} else {
			setLoading(false);
		}
	}, [isLogin]);

	return (
		<>
			<Navbar />
			<CategoryNavbar />
			{loading ? (
				<Spinner />
			) : (
				<div className="container">
					<div className="row my-4 border-bottom pb-4">
						<div className="col-12 d-flex justify-content-between align-items-center">
							<h1 className="text-capitalize h2">Checkout</h1>
						</div>
					</div>
					<div className="row m-4 m-sm-0">
						<div className="col-md-9 p-2 border-end">
							<div className="row">
								<div className="col-9">
									<h4>Shiping Address</h4>
								</div>
								<div className="col-3 d-flex justify-content-end align-items-center pe-5">
									<button
										className="btn btn-tertiary fs-8"
										data-bs-toggle="modal"
										data-bs-target={
											addressList.length > 0
												? "#addressModal"
												: "#addAddressModal"
										}
									>
										{addressList.length > 0
											? "Change"
											: "Add"}
									</button>
								</div>
							</div>
							<div className="row d-flex align-items-start">
								<div className="col-9">
									{selectedAddress ? (
										<div className="row">
											<div className="col-12">
												<p className="mb-0">
													{selectedAddress.name}
												</p>
												<p className="mb-0">
													{
														selectedAddress.phone_number
													}
												</p>
												<p className="mb-0">
													{
														selectedAddress.address_line_1
													}
													,{" "}
													{
														selectedAddress.address_line_2
													}
													, {selectedAddress.city},{" "}
													{selectedAddress.state},{" "}
													{selectedAddress.country},{" "}
													{selectedAddress.pin_code}
												</p>
											</div>
										</div>
									) : (
										<div className="row">
											<div className="col-12">
												<p className="mb-0">
													No Address Found
												</p>
											</div>
										</div>
									)}
								</div>
								<div className="col-3 checkout-address d-flex justify-content-end align-items-center pe-5">
									{addressList.length > 0 && (
										<button
											className="btn sizeBtn fs-8"
											data-bs-toggle="modal"
											data-bs-target={"#addAddressModal"}
											onClick={() => {
												setNewAddress({
													type: selectedAddress.address_type,
													name: selectedAddress.name,
													phone: selectedAddress.phone_number,
													addressLine1:
														selectedAddress.address_line_1,
													addressLine2:
														selectedAddress.address_line_2,
													pincode:
														selectedAddress.pin_code,
													city: selectedAddress.city,
													state: selectedAddress.state,
													country:
														selectedAddress.country,
												});
												setEditAddress(true);
											}}
										>
											Edit
										</button>
									)}
								</div>
							</div>
							<div className="ro mt-3">
								<div className="col-12">
									<table className="table table-borderless">
										<thead>
											<tr className="fs-5">
												<th className="px-0">
													Product
												</th>
												<th className="px-0">
													Quantity
												</th>
												<th className="px-0">Price</th>
											</tr>
										</thead>
										<tbody>
											{cart.length > 0 ? (
												cart.map((item) => (
													<tr
														key={item.id}
														className="align-middle"
													>
														<td className="px-0 product-table">
															<img
																src={item.image}
																alt={item.name}
																className="img-fluid"
																style={{
																	width: "60px",
																	height: "60px",
																	borderRadius:
																		"10px",
																}}
															/>
															<span className="ms-2">
																{item.name}
															</span>
														</td>
														<td className="px-0">
															<div className="d-flex border rounded col-6 checkout-quantity w-100">
																<button
																	className="btn btn-sm flex-1"
																	onClick={() =>
																		updateCart(
																			item,
																			"dec"
																		)
																	}
																>
																	{" "}
																	-{" "}
																</button>
																<input
																	type="text"
																	readOnly
																	id=""
																	value={
																		item.quantity
																	}
																	className="text-center flex-1"
																	style={{
																		width: "25px",
																		border: "0px",
																	}}
																/>
																<button
																	className="btn btn-sm flex-1"
																	onClick={() =>
																		updateCart(
																			item,
																			"inc"
																		)
																	}
																>
																	{" "}
																	+{" "}
																</button>
															</div>
														</td>
														<td className="px-0">
															{item.quantity} x
															&#8377; {item.price}
														</td>
													</tr>
												))
											) : (
												<>
													<tr>
														<td
															colSpan="3"
															className="text-center"
														>
															<Empty
																style={{
																	height: "100px",
																	width: "100px",
																}}
															/>
														</td>
													</tr>
													<tr>
														<td
															colSpan="3"
															className="text-center"
														>
															No items in cart
														</td>
													</tr>
												</>
											)}
										</tbody>
									</table>
								</div>
							</div>
							<div className="row mt-4">
								<div className="col-12">
									<h4>Coupons</h4>
								</div>
							</div>
							<div className="row d-flex align-items-center w-100 mb-4">
								<div className="col-9 col-md-6 pe-0 h-100">
									<input
										type="text"
										className="form-control"
										value={couponCode}
										onChange={(e) =>
											setCouponCode(e.target.value)
										}
										placeholder="Enter coupon code"
									/>
								</div>
								<div className="col-3 px-0">
									<button
										className="btn btn-tertiary"
										onClick={checkCoupon}
									>
										Apply
									</button>
								</div>
							</div>
							<div className="row mt-4">
								<div className="col-12">
									<h4>Sales Reference</h4>
								</div>
							</div>
							<div className="row d-flex align-items-center w-100 mb-4">
								<div className="col-9 col-md-6 pe-0 h-100">
									<input
										type="text"
										className="form-control"
										value={salesReference}
										onChange={(e) =>
											setSalesReference(e.target.value)
										}
										placeholder="Enter sales reference"
									/>
								</div>
							</div>
						</div>
						<div className="col-md-3 px-3">
							<div className="row py-3">
								<div className="col-12">
									<h5>Order Summary</h5>
								</div>
							</div>
							<div className="row">
								<div className="col-12">
									<div className="row">
										<div className="col-6">
											<p>Subtotal</p>
										</div>
										<div className="col-6">
											<p>&#8377; {subTotal}</p>
										</div>
									</div>
									<div className="row">
										<div className="col-6">
											<p>Coupon Discount</p>
										</div>
										<div className="col-6">
											<p>{discount} %</p>
										</div>
									</div>
									<div className="row">
										<div className="col-6">
											<p>You saved</p>
										</div>
										<div className="col-6">
											<p>&#8377; {priceSaved}</p>
										</div>
									</div>
									{/* {paymentMode === "Cash On Delivery" && (
										<div className="row">
											<div className="col-6">
												<p>COD charges</p>
											</div>
											<div className="col-6">
												<p>&#8377; 80</p>
											</div>
										</div>
									)} */}
									<hr />
									<div className="row">
										<div className="col-6">
											<p className="fw-bold fs-4">
												Total
											</p>
										</div>
										<div className="col-6">
											<p className="fw-bold fs-5">
												&#8377; {grandTotal}
											</p>
										</div>
									</div>
									<div className="row mb-3">
										<div className="col-12">
											<button
												className={`btn btn-tertiary w-100 ${
													grandTotal === "0.00" ||
													paymentMode === null ||
													selectedAddress === null
														? "btn-disabled"
														: ""
												}`}
												onClick={handleOrder}
												disabled={
													grandTotal === "0.00" ||
													paymentMode === null
												}
											>
												Place Order
											</button>
										</div>
									</div>
									<div className="row">
										<div className="col-12">
											<h5>Payment Mode</h5>
										</div>
									</div>
									<div className="d-flex align-items-center flex-row">
										<div className="">
											<div
												className={`btn fs-8 sizeBtn ${
													paymentMode === "Online"
														? "sizeBtn-selected"
														: ""
												}`}
												onClick={() => setPaymentMode("Online")}
											>
												Online Payment
											</div>
										</div>
										<div className="">
											<div
												className={`btn fs-8 sizeBtn ${
													paymentMode === "Cash On Delivery"
														? "sizeBtn-selected"
														: ""
												}`}
												onClick={() =>
													setPaymentMode("Cash On Delivery")
												}
											>
												Cash On Delivery
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
			<button
				className="d-none"
				data-bs-toggle="modal"
				data-bs-target={"#guestModal"}
				ref={guestModalRef}
			>
				open guest modal
			</button>
			<div
				className="modal fade"
				id="addressModal"
				tabIndex="-1"
				aria-labelledby="exampleModalLabel"
				aria-hidden="true"
			>
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<h1
								className="modal-title fs-5"
								id="exampleModalLabel"
							>
								Select Address
							</h1>
							<button
								type="button"
								className="btn-close"
								data-bs-dismiss="modal"
								aria-label="Close"
							></button>
						</div>
						<div className="modal-body">
							<div className="container">
								<div className="row">
									{addressList?.length > 0 ? (
										addressList?.map((item, index) => (
											<div
												className="col-12 cursor-pointer mb-1"
												key={index}
												data-bs-dismiss="modal"
												onClick={() =>
													setSelectedAddress(item)
												}
											>
												<div
													className={`border p-2 rounded ${
														selectedAddress.id ===
														item.id
															? "border-brown"
															: ""
													}`}
												>
													<div className="mb-0 fs-7 d-flex justify-content-between align-items center">
														<div className="fw-bold d-flex gap-2 align-items-end">
															<div className="fs-6">
																{item.name}
															</div>
															<div className="fw-normal fs-8 mb-0-5">
																{
																	item.phone_number
																}
															</div>
														</div>
														<div className="text-white bg-tertiary py-1 px-2 rounded d-flex justify-content-center align-items-center">
															{item.address_type}
														</div>
													</div>
													<div className="mt-2 fs-7">
														{item.address_line_1},{" "}
														{item.address_line_2},{" "}
														{item.city},{" "}
														{item.state},{" "}
														{item.country},{" "}
														{item.pin_code}
													</div>
												</div>
											</div>
										))
									) : (
										<div className="col-12">
											No Address Found
										</div>
									)}
								</div>
								<hr />
								<div className="row">
									<div className="col-12 py-3">
										<button
											className="btn btn-tertiary w-100 fs-6"
											data-bs-toggle="modal"
											data-bs-target="#addAddressModal"
										>
											Add New Address
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="modal fade"
				id="addAddressModal"
				tabIndex="-1"
				aria-labelledby="exampleModalLabel"
				aria-hidden="true"
			>
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<h1
								className="modal-title fs-5"
								id="exampleModalLabel"
							>
								Add New Address
							</h1>
							<button
								type="button"
								className="btn-close"
								data-bs-dismiss="modal"
								aria-label="Close"
							></button>
						</div>
						<div className="modal-body">
							<div className="container">
								<div className="row mx-0 justify-content-center">
									<div className="col-12 d-flex flex-column gap-3">
										<div className="row d-flex align-items-center">
											<div className="col-4">
												<div
													className={`btn w-100 fs-7 sizeBtn ${
														newAddress.type ===
														"Home"
															? "sizeBtn-selected"
															: ""
													}`}
													onClick={() =>
														setNewAddress({
															...newAddress,
															type: "Home",
														})
													}
												>
													Home
												</div>
											</div>
											<div className="col-4">
												<div
													className={`btn w-100 fs-7 sizeBtn ${
														newAddress.type ===
														"Office"
															? "sizeBtn-selected"
															: ""
													}`}
													onClick={() =>
														setNewAddress({
															...newAddress,
															type: "Office",
														})
													}
												>
													Office
												</div>
											</div>
											<div className="col-4">
												<div
													className={`btn w-100 fs-7 sizeBtn ${
														newAddress.type ===
														"Other"
															? "sizeBtn-selected"
															: ""
													}`}
													onClick={() =>
														setNewAddress({
															...newAddress,
															type: "Other",
														})
													}
												>
													Other
												</div>
											</div>
										</div>
										<input
											name="name"
											type="text"
											className="form-control"
											placeholder="Name *"
											value={newAddress.name}
											onChange={(e) =>
												setNewAddress({
													...newAddress,
													name: e.target.value,
												})
											}
										/>
										<input
											name="name"
											type="text"
											className="form-control"
											placeholder="Phone Number *"
											maxLength={10}
											minLength={10}
											value={newAddress.phone}
											onChange={(e) =>
												setNewAddress({
													...newAddress,
													phone: e.target.value,
												})
											}
										/>
										<input
											name="address1"
											type="text"
											className="form-control"
											placeholder="Address Line 1 *"
											value={newAddress.addressLine1}
											onChange={(e) =>
												setNewAddress({
													...newAddress,
													addressLine1:
														e.target.value,
												})
											}
										/>
										<input
											name="address1"
											type="text"
											className="form-control"
											placeholder="Address Line 2"
											value={newAddress.addressLine2}
											onChange={(e) =>
												setNewAddress({
													...newAddress,
													addressLine2:
														e.target.value,
												})
											}
										/>
										<input
											name="address1"
											type="text"
											className="form-control"
											placeholder="Pincode *"
											value={newAddress.pincode}
											onChange={(e) =>
												setNewAddress({
													...newAddress,
													pincode: e.target.value,
												})
											}
										/>
										<input
											name="address1"
											type="text"
											className="form-control"
											placeholder="City *"
											value={newAddress.city}
											onChange={(e) =>
												setNewAddress({
													...newAddress,
													city: e.target.value,
												})
											}
										/>
										<input
											name="address1"
											type="text"
											className="form-control"
											placeholder="State *"
											value={newAddress.state}
											onChange={(e) =>
												setNewAddress({
													...newAddress,
													state: e.target.value,
												})
											}
										/>
										<input
											name="address1"
											type="text"
											className="form-control"
											placeholder="Country *"
											value={newAddress.country}
											onChange={(e) =>
												setNewAddress({
													...newAddress,
													country: e.target.value,
												})
											}
										/>
									</div>
								</div>
							</div>
						</div>
						<div className="modal-footer">
							<button
								type="button"
								className="btn btn-tertiary"
								data-bs-dismiss="modal"
								onClick={() => handleAddAddress()}
							>
								Save
							</button>
						</div>
					</div>
				</div>
			</div>
			<GuestUserLoginModal />
			<Footer />
		</>
	);
};

export default Checkout;
